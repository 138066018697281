let requestedNotificationPermission = false;

// showNotification displays a platform notification with the configured parameters.
//
// If successful in showing a notification, it resolves with a callback to manually close the
// notification. Notifications that do not require interaction will be closed automatically after
// the Constants.DEFAULT_NOTIFICATION_DURATION. Not all platforms support all features, and may
// choose different semantics for the notifications.

export interface ShowNotificationParams {
  title: string;
  body: string;
  requireInteraction: boolean;
  silent: boolean;
  tag: string;
  onClick?: (this: Notification, e: Event) => any | null;
}

export async function showNotification(
  {
    title,
    body,
    requireInteraction,
    silent,
    tag,
    onClick
  }: ShowNotificationParams = {
    title: "",
    body: "",
    tag: "",
    requireInteraction: false,
    silent: false
  }
) {
  if (!("Notification" in window)) {
    throw new Error("Notification not supported");
  }

  if (typeof Notification.requestPermission !== "function") {
    throw new Error("Notification.requestPermission not supported");
  }

  if (
    Notification.permission !== "granted" &&
    requestedNotificationPermission
  ) {
    throw new Error("Notifications already requested but not granted");
  }

  requestedNotificationPermission = true;

  let permission = await Notification.requestPermission();
  if (typeof permission === "undefined") {
    // Handle browsers that don't support the promise-based syntax.
    permission = await new Promise(resolve => {
      Notification.requestPermission(resolve);
    });
  }

  if (permission !== "granted") {
    throw new Error("permissions not granted");
  }
  // var img = "/assets/AppIcon/IntivaHealth_2018_Icon_1024x1024.png";
  var img = "IntivaHealth_2018_Icon_1024x1024.png";
  const notification = new Notification(title, {
    body,
    requireInteraction,
    silent,
    icon: img
    //tag
  });

  if (onClick) {
    notification.onclick = onClick;
  }

  notification.onerror = () => {
    throw new Error("Notification failed to show.");
  };

  /*  // Mac desktop app notification dismissal is handled by the OS
  if (!requireInteraction && !UserAgent.isMacApp()) {
    setTimeout(() => {
      notification.close();
    }, 10000);
  } */

  return () => {
    notification.close();
  };
}
