import React, { Component } from "react";
import Button from "@salesforce/design-system-react/components/button";
import _ from "lodash";
export default class EmojiBody extends Component {
  constructor(props) {
    super(props);
    this.state = { startIndex: 0, endIndex: 120 };
  }
  render() {
    return (
      <div>
        <dl className="slds-popover__body-list ">
          <dt className="slds-m-bottom_small">
            <Button
              onClick={e => {
                this.setState({
                  startIndex: 0,
                  endIndex: 120
                });
              }}
              iconVariant="border"
            >
              {emojis[0]}
            </Button>
            <Button
              onClick={e => {
                this.setState({
                  startIndex: 121,
                  endIndex: 164
                });
              }}
              iconVariant="border"
            >
              {emojis[121]}
            </Button>
            <Button
              onClick={e => {
                this.setState({
                  startIndex: 165,
                  endIndex: 242
                });
              }}
              iconVariant="border"
            >
              {emojis[165]}
            </Button>
            <Button
              onClick={e => {
                this.setState({
                  startIndex: 243,
                  endIndex: 318
                });
              }}
              iconVariant="border"
            >
              {emojis[243]}
            </Button>
            <Button
              onClick={() => {
                console.log(this.state.startIndex);
                console.log(this.state.endIndex);
                this.setState({
                  startIndex: 319,
                  endIndex: 382
                });
              }}
              iconVariant="border"
            >
              {emojis[319]}
            </Button>
          </dt>
          <dd className="slds-tile">
            <ul
              style={{
                height: "100px",
                overflow: "hidden",
                "overflow-y": "scroll"
              }}
            >
              {_.times(this.state.endIndex - this.state.startIndex, index => (
                <button
                  style={{ margin: "2.5px", display: "inline-block" }}
                  class="slds-button"
                  onClick={() =>
                    this.props.onEmojiClick(this.state.startIndex + index)
                  }
                >
                  {emojis[this.state.startIndex + index]}
                </button>
              ))}
            </ul>
          </dd>
        </dl>
      </div>
    );
  }
}
const emojis = require("emojis-list");
