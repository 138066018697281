import React from "react";
import Card from "@salesforce/design-system-react/components/card";
import Avatar from "@material-ui/core/Avatar";
import Input from "@salesforce/design-system-react/components/input";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { Scrollbars } from "react-custom-scrollbars";
import * as items from "./Items.jsx";
import _ from "lodash";
import * as SendBirdConfig from "./Config/SendBirdConfig";
import Button from "@salesforce/design-system-react/components/button";
import AddMembers from "./AddMembers";
import { UserChatIcon, getProfileURL } from "./Utils/IntivaUtils.jsx";
import * as utils from "./Utils/utils.ts";

class RightPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      memberMap: new Map(),
      groupMap: new Map(),
      value: this.props.name,
      toggleHover: false,
      toggleRemove: false,
      addMembers: false
    };
    this.fileInput = React.createRef();
    this.getMemberMap = this.getMemberMap.bind(this);

    this.updateGroupName = this.updateGroupName.bind(this);
    this.updateGroupCover = this.updateGroupCover.bind(this);
    this.leaveGroup = this.leaveGroup.bind(this);
    this.removeMember = this.removeMember.bind(this);
    this.addMembers = this.addMembers.bind(this);
  }
  componentDidMount() {
    if (this.props.peerUserIds.length > 1) this.getMemberMap();
  }
  componentDidUpdate(nextProps, prevState, snapshot) {
    //console.log("componentDidUpdate" + nextProps.peerUser);
    //TODO correct the If condition accordinly
    if (this.props.peerUserIds !== nextProps.peerUserIds) {
      console.log("this.props.isGroup");
      console.log(this.props.isGroup);
      if (this.props.peerUserIds.length > 1) this.getMemberMap();
    }
  }
  getMemberMap() {
    console.log("getMemberMap");
    var memberMap = new Map();
    this.props.directoryMap.forEach(item => {
      if (this.props.peerUserIds.includes(item.userId)) {
        memberMap.set(item.userId, item);
      }
    });
    this.setState({
      memberMap: memberMap,
      value: this.props.name
    });
  }
  updateGroupName() {
    console.log(this.props.groupChannel);
    var params = new SendBirdConfig.sb.GroupChannelParams();
    params.name = this.state.value;
    console.log("createGroup");
    console.log(params);
    this.props.groupChannel.updateChannel(params, function(
      groupChannel,
      error
    ) {
      if (error) {
        console.log(error);
        return;
      }
      console.log("createGroup");
      console.log(groupChannel);
    });
  }
  updateGroupCover() {
    console.log("updateGroupCover");
    if (this.fileInput.current.files.length === 0) {
      return;
    }
    console.log(this.props.groupChannel);
    var params = new SendBirdConfig.sb.GroupChannelParams();
    params.coverImage = this.fileInput.current.files[0];

    this.props.groupChannel.updateChannel(params, function(
      groupChannel,
      error
    ) {
      if (error) {
        console.log(error);
        return;
      }

      console.log("updateGroupCover");
      console.log(groupChannel);
    });
  }
  removeMember(item) {
    console.log(item);
    var userIds = ["Bernadette"];
    this.props.groupChannel.inviteWithUserIds(userIds, function(
      response,
      error
    ) {
      if (error) {
        console.log(response);
        return;
      }
      console.log(response);
    });
  }
  addMembers(items) {
    var that = this;
    this.props.groupChannel.inviteWithUserIds(items, function(response, error) {
      if (error) {
        console.log(response);
        return;
      }
      console.log("addMembers");
      console.log(items);
      items.forEach(item => {
        that.props.sendEventToSB(
          "Event",
          "added " + that.props.directoryMap.get(item).name + " to this group"
        );
      });
      console.log(response);
    });
    this.setState({ addMembers: false });
  }
  leaveGroup() {
    const params = new SendBirdConfig.sb.UserMessageParams();
    params.customType = "Event";
    params.message = "left the Group";

    this.props.sendEventToSB("Event", "left the Group");

    var that = this;
    setTimeout(function() {
      that.props.groupChannel.leave(function(response, error) {
        if (error) {
          console.log(error);
          return;
        }
        console.log(response);
        that.props.close();
      });
    }, 1000);
  }

  render() {
    return (
      <div
        style={{
          width: "40%",
          backgroundColor: "rgb(0,0,0,0.05)",
          height: this.props.height,
          overflow: "scroll"
        }}
      >
        <div style={{ marginBottom: "40px" }} onClick={this.props.close}>
          <BackIcon
            style={{
              color: "rgb(0,0,0,0.2)",
              float: "right",
              cursor: "pointer",
              marginRight: "10px",
              marginTop: "10px"
            }}
          />
        </div>
        <div className="slds-align_absolute-center">
          {!this.props.isGroup &&
            (this.props.peerUserIds.length === 1 ? (
              // this is material Avatar not SLDS
              <Avatar
                style={{
                  width: "120px",
                  height: "120px",
                  marginBottom: "10px"
                }}
                variant="entity"
                label={this.props.name}
                src={getProfileURL(
                  utils.directoryMap.get(this.props.peerUserIds[0]).imgsrc
                )}
              />
            ) : (
              <UserChatIcon
                style={{
                  width: "120px",
                  height: "120px",
                  marginBottom: "10px",
                  backgroundColor: "#7e8be4",
                  borderRadius: "10px",
                  color: "white",
                  padding: "20px"
                }}
              />
            ))}
          {this.props.isGroup && (
            <div
              style={{ position: "relative" }}
              onMouseEnter={() =>
                this.setState({
                  toggleHover: true
                })
              }
              onMouseLeave={() =>
                this.setState({
                  toggleHover: false
                })
              }
            >
              {this.props.imgsrc !== "" ? (
                // this is material Avatar not SLDS
                <Avatar
                  style={{
                    width: "120px",
                    height: "120px",
                    marginBottom: "10px",
                    filter: this.state.toggleHover ? "blur(2px)" : "",
                    WebkitFilter: this.state.toggleHover ? "blur(2px)" : ""
                  }}
                  src={this.props.imgsrc}
                />
              ) : (
                <GroupChatIcon
                  style={{
                    width: "120px",
                    height: "120px",
                    marginBottom: "10px",
                    filter: this.state.toggleHover ? "blur(2px)" : "",
                    WebkitFilter: this.state.toggleHover ? "blur(2px)" : "",
                    backgroundColor: "#7e8be4",
                    borderRadius: "10px",
                    color: "white",
                    padding: "20px"
                  }}
                />
              )}
              {this.state.toggleHover && this.props.isGroup && (
                <div
                  style={{
                    position: "absolute",
                    left: 15,
                    top: "25%",
                    textAlign: "center"
                  }}
                >
                  <Button
                    iconPath="symbols.svg#image"
                    iconPosition="left"
                    label="update"
                    size="small"
                    onClick={() => {
                      this.fileInput.current.click();
                    }}
                  />
                </div>
              )}
              <input
                style={{ display: "none" }}
                type="file"
                accept="image/png, image/jpeg"
                title="Add Cover Image"
                ref={this.fileInput}
                onChange={this.updateGroupCover}
                autoFocus={true}
              />
            </div>
          )}
        </div>

        {this.props.isGroup ? (
          <div className="slds-grid slds-grid_vertical  slds-align_absolute-center">
            <Input
              styleInput={{
                fontSize: "18px",
                backgroundColor: "transparent",
                border: "none",
                outline: 0,
                textAlign: "center"
              }}
              value={this.state.value}
              onChange={event => {
                this.setState({ value: event.target.value });
              }}
              className="slds-col"
            ></Input>
            {!(this.state.value === this.props.name) && (
              <div>
                <Button
                  iconCategory="utility"
                  iconName=""
                  iconPosition="right"
                  label="Save"
                  size="small"
                  variant="base"
                  onClick={this.updateGroupName}
                />
                <Button
                  iconCategory="utility"
                  iconName=""
                  iconPosition="right"
                  label="Cancel"
                  size="small"
                  variant="base"
                  onClick={() => this.setState({ value: this.props.name })}
                />
              </div>
            )}

            <div className="slds-col">
              {this.props.peerUserIds.length} Members
            </div>
          </div>
        ) : (
          <div className="slds-grid slds-grid_vertical  slds-align_absolute-center">
            <span
              style={{
                fontSize: "18px"
              }}
              className="slds-align_absolute-center"
            >
              {this.props.name}
            </span>
            {this.props.peerUserIds.length > 1 && (
              <div className="slds-col">
                {this.props.peerUserIds.length} Members
              </div>
            )}
          </div>
        )}
        <Card
          hasNoHeader={true}
          style={{
            marginTop: "15px",
            marginLeft: "15px",
            marginRight: "15px",
            borderRadius: "10px"
          }}
        >
          <List
            subheader={<ListSubheader component="div">Options</ListSubheader>}
          >
            {this.props.isGroup && (
              <ListItem button onClick={this.leaveGroup}>
                Leave Group
              </ListItem>
            )}
            <ListItem button onClick={this.props.setPrintMode}>
              Print Mode
            </ListItem>
            <ListItem button onClick={this.props.pinConversation}>
              {this.props.pin ? "Unpin" : "Pin"} Conversation
            </ListItem>
            <ListItem button onClick={this.props.clearConnversation}>
              Clear Conversation
            </ListItem>
          </List>
        </Card>
        {this.props.isGroup && (
          <Card
            hasNoHeader={true}
            style={{
              marginTop: "20px",
              marginLeft: "15px",
              marginRight: "15px",
              marginBottom: "15px",
              borderRadius: "10px"
            }}
          >
            <Scrollbars
              style={{
                height: 240
              }}
            >
              <List
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
                subheader={
                  <ListSubheader component="div">Members</ListSubheader>
                }
              ></List>
              <ul>
                <li>
                  <Button
                    style={{
                      paddingLeft: "15px",
                      paddingRight: "8px"
                    }}
                    iconPath="symbols.svg#addUserPlus"
                    iconPosition="left"
                    label="Add People"
                    size="medium"
                    variant="base"
                    onClick={() => this.setState({ addMembers: true })}
                  />
                </li>
                {_.orderBy(
                  Array.from(this.state.memberMap.values()),
                  ["name"],
                  ["asc"]
                ).map((item, index) => (
                  <div
                    className="slds-grid"
                    onMouseEnter={() =>
                      this.setState({
                        toggleRemove: true
                      })
                    }
                    onMouseLeave={() =>
                      this.setState({
                        toggleRemove: false
                      })
                    }
                  >
                    <items.DirectoryItem key={index} item={item} />
                    {this.state.toggleRemove && (
                      <Button
                        style={{ border: "none", outline: 0 }}
                        iconPath="symbols.svg#close"
                        onClick={() => {
                          this.removeMember(item);
                        }}
                      />
                    )}
                  </div>
                ))}
              </ul>
            </Scrollbars>
          </Card>
        )}
        {this.props.peerUserIds.length > 1 && !this.props.isGroup && (
          <Card
            hasNoHeader={true}
            style={{
              marginTop: "20px",
              marginLeft: "15px",
              marginRight: "15px",
              marginBottom: "15px",
              borderRadius: "10px"
            }}
          >
            <Scrollbars
              style={{
                height: 240
              }}
            >
              <List
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
                subheader={
                  <ListSubheader component="div">Members</ListSubheader>
                }
              ></List>
              <ul>
                {_.orderBy(
                  Array.from(this.state.memberMap.values()),
                  ["name"],
                  ["asc"]
                ).map((item, index) => (
                  <div className="slds-grid">
                    <items.DirectoryItem key={index} item={item} />
                  </div>
                ))}
              </ul>
            </Scrollbars>
          </Card>
        )}

        <AddMembers
          addMembers={this.state.addMembers}
          close={() => {
            this.setState({ addMembers: false });
          }}
          members={new Set()}
          directoryMap={this.props.directoryMap}
          addMembersToGroup={this.addMembers}
        />
      </div>
    );
  }
}

export function GroupChatIcon(props) {
  return (
    <svg
      style={props.style}
      width="20px"
      height="20px"
      role="img"
      viewBox="0 0 640 512"
    >
      <g>
        <path
          fill="currentColor"
          d="M96 224a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64zm480 32h-64a63.81 63.81 0 0 0-45.1 18.6A146.27 146.27 0 0 1 542 384h66a32 32 0 0 0 32-32v-32a64.06 64.06 0 0 0-64-64zm-512 0a64.06 64.06 0 0 0-64 64v32a32 32 0 0 0 32 32h65.9a146.64 146.64 0 0 1 75.2-109.4A63.81 63.81 0 0 0 128 256zm480-32a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64z"
          opacity="0.4"
        ></path>
        <path
          fill="currentColor"
          d="M396.8 288h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 128 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 396.8 288zM320 256a112 112 0 1 0-112-112 111.94 111.94 0 0 0 112 112z"
        ></path>
      </g>
    </svg>
  );
}

export function BackIcon(props) {
  return (
    <svg
      style={props.style}
      width="20px"
      height="20px"
      class="svg-inline--fa fa-address-book fa-w-14"
      role="img"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
      ></path>
    </svg>
  );
}
export default RightPanel;
