import React from 'react';
import './App.css';
import * as items from './Items.jsx';
import { SearchIcon, getProfileURL } from './Utils/IntivaUtils.jsx'
import * as utils from './Utils/utils.ts'
import NewGroup from "./newGroup";
import { showNotification } from './Utils/notifications.tsx';
import MessageBoard from "./messageBoard";
import NewMessage from "./NewMessage";
import { Scrollbars } from 'react-custom-scrollbars';
import * as SendBirdConfig from "./Config/SendBirdConfig";
import Divider from '@material-ui/core/Divider';
import PrimarySearchAppBar from './PrimarySearchAppBar.tsx';
import _ from 'lodash';
import Popover from "@salesforce/design-system-react/components/popover";
import Spinner from '@salesforce/design-system-react/components/spinner';
import { signIn, firebaseApp, getDirectory } from './firebaseUtils.js';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inbox: [],
            selected: {},
            currentUser: {},
            inboxMap: new Map(),
            tabIndex: 0,
            channel: "",
            filteredMap: new Map(),
            currentMap: new Map(),
            directoryMap: new Map(),
            groupMap: new Map(),
            width: 0,
            height: 0,
            newChat: false,
            value: "",
            sortBy: "Name",
            newGroup: false,
            printMode: false,
            isloading: true,
            fmgName: '',
            name: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.getUsersList = this.getUsersList.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.inbox = this.inbox.bind(this);
        this.MessageReceived = this.MessageReceived.bind(this);
        this.ChannelChanged = this.ChannelChanged.bind(this);
        this.UserReceivedInvitation = this.UserReceivedInvitation.bind(this);
        this.filter = this.filter.bind(this);
        this.closeNewMessage = this.closeNewMessage.bind(this);
        this.openNewGroup = this.openNewGroup.bind(this);
        this.createNewDirectGroup = this.createNewDirectGroup.bind(this);
        this.addMessageReceivedListener = this.addMessageReceivedListener.bind(this);
        this.componentCleanup = this.componentCleanup.bind(this);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight - 120 - 64, });
    }
    MessageReceived(channel, message) {
        console.log("MessageReceived in App.js");
        console.log(message);
        console.log(channel);

        if (Notification.permission === "granted" && !document.hasFocus() && message._sender.userId !== this.state.currentUser.userId) {
            showNotification({
                title: message._sender.nickname,
                body: message.messageType === "user" ? message.message : "sent you a file",
                requireInteraction: message.customType === "priority",
                silent: false,
                onClick: () => {
                    this.setState({ channel: channel.url });
                    window.parent.focus();
                },
            }).catch((error) => {
                console.log(error);
            });
        }
    }
    UserReceivedInvitation(channel) {
        console.log("UserReceivedInvitation in App.js");
        console.log(channel.url);
        // profile pic of the channel this for a 1-1 this should be the peer User Profile pic and 
        // for direct message with multiple people, their is no picture by default so display just the group name
        // is  a direct message channel or a group channel
        var isGroup = !channel.isDistinct;
        // peerUserIds has all the Userids of the channel except currentUser.userId
        var peerUserIds = Object.keys(channel.memberMap);
        peerUserIds = peerUserIds.filter(
            peerUserId => peerUserId !== this.state.currentUser.userId
        );
        console.log(peerUserIds);
        var name = utils.getChannelName(isGroup, channel.name, peerUserIds, this.state.currentUser.userId);
        console.log(name);
        var lastMessage = utils.getLastMessage(channel, this.state.currentUser.userId, isGroup);
        console.log(lastMessage);
        var imgsrc = peerUserIds.length === 1 ? getProfileURL(utils.directoryMap.get(peerUserIds[0]).imgsrc) :
            channel.coverUrl;
        console.log(channel.customType);
        if (channel.customType === "") {
            var updatedInboxMap = new Map(this.state.inboxMap);
            updatedInboxMap.set(channel.url, {
                name: name,
                peerUserIds: peerUserIds,
                lastMessage: lastMessage,
                channel: channel.url,
                isGroup: isGroup,
                time: channel.lastMessage !== null ? channel.lastMessage.createdAt : 0,
                unread: channel.unreadMessageCount,
                imgsrc: imgsrc,
                pin: JSON.parse(channel.data).pin
            });
            console.log(updatedInboxMap.get(channel.url));
            if (this.state.tabIndex === 0) {
                this.setState({
                    inboxMap: updatedInboxMap,
                    currentMap: new Map([...updatedInboxMap, ...this.state.groupMap]),
                }, () => { this.filter(this.state.value) })
            } else {
                this.setState({
                    inboxMap: updatedInboxMap,
                }, () => { this.filter(this.state.value) })
            }
        }
        else {
            let updatedGroupMap = new Map(this.state.groupMap);
            updatedGroupMap.set(channel.url, {
                name: name,
                peerUserIds: peerUserIds,
                imgsrc: imgsrc,
                lastMessage: lastMessage,
                channel: channel.url,
                isGroup: isGroup,
                time: channel.lastMessage !== null ? channel.lastMessage.createdAt : 0,
                unread: channel.unreadMessageCount,
                pin: JSON.parse(channel.data).pin
            });
            console.log(updatedGroupMap.get(channel.url));
            if (this.state.tabIndex === 2) {
                this.setState({
                    groupMap: updatedGroupMap,
                    currentMap: updatedGroupMap,
                }, () => { this.filter(this.state.value) })
            } if (this.state.tabIndex === 0) {
                this.setState({
                    groupMap: updatedGroupMap,
                    currentMap: new Map([...updatedGroupMap, ...this.state.inboxMap]),
                }, () => { this.filter(this.state.value) })
            } else {
                this.setState({
                    groupMap: new Map(updatedGroupMap),
                }, () => { this.filter(this.state.value) })
            }
        }
    }
    ChannelChanged(channel) {
        // ****** changes Made here must also be made in onChannelChanged  and getUsersList and UserReceivedInvitation
        console.log("onChannelChanged in App.js");
        console.log(channel.url);
        // profile pic of the channel this for a 1-1 this should be the peer User Profile pic and 
        // for direct message with multiple people, their is no picture by default so display just the group name

        // is  a direct message channel or a group channel
        var isGroup = !channel.isDistinct;
        // peerUserIds has all the Userids of the channel except currentUser.userId
        var peerUserIds = Object.keys(channel.memberMap);
        peerUserIds = peerUserIds.filter(
            peerUserId => peerUserId !== this.state.currentUser.userId
        );
        console.log(peerUserIds);
        var name = utils.getChannelName(isGroup, channel.name, peerUserIds, this.state.currentUser.userId);
        console.log(name);
        var lastMessage = utils.getLastMessage(channel, this.state.currentUser.userId, isGroup);
        console.log(lastMessage);
        var imgsrc = peerUserIds.length === 1 ? getProfileURL(utils.directoryMap.get(peerUserIds[0]).imgsrc) :
            channel.coverUrl;

        if (channel.customType === "") {
            var updatedInboxMap = new Map(this.state.inboxMap);
            updatedInboxMap.set(channel.url, {
                name: name,
                peerUserIds: peerUserIds,
                lastMessage: lastMessage,
                channel: channel.url,
                isGroup: isGroup,
                time: channel.lastMessage !== null ? channel.lastMessage.createdAt : 0,
                unread: channel.unreadMessageCount,
                imgsrc: imgsrc,
                pin: JSON.parse(channel.data).pin
            });
            console.log(updatedInboxMap.get(channel.url));
            if (this.state.tabIndex === 0) {
                this.setState({
                    inboxMap: updatedInboxMap,
                    currentMap: new Map([...updatedInboxMap, ...this.state.groupMap]),
                }, () => { this.filter(this.state.value) })
            } else {
                this.setState({
                    inboxMap: updatedInboxMap,
                }, () => { this.filter(this.state.value) })
            }
        }
        else {
            let updatedGroupMap = new Map(this.state.groupMap);
            updatedGroupMap.set(channel.url, {
                name: name,
                peerUserIds: peerUserIds,
                imgsrc: imgsrc,
                lastMessage: lastMessage,
                channel: channel.url,
                isGroup: isGroup,
                time: channel.lastMessage !== null ? channel.lastMessage.createdAt : 0,
                unread: channel.unreadMessageCount,
                pin: JSON.parse(channel.data).pin
            });
            console.log(updatedGroupMap.get(channel.url));
            if (this.state.tabIndex === 2) {
                this.setState({
                    groupMap: updatedGroupMap,
                    currentMap: updatedGroupMap,
                }, () => { this.filter(this.state.value) })
            } if (this.state.tabIndex === 0) {
                this.setState({
                    groupMap: updatedGroupMap,
                    currentMap: new Map([...updatedGroupMap, ...this.state.inboxMap]),
                }, () => { this.filter(this.state.value) })
            } else {
                this.setState({
                    groupMap: new Map(updatedGroupMap),
                }, () => { this.filter(this.state.value) })
            }
        }
    }
    componentCleanup() {
        // this will hold the cleanup code
        console.log("componentCleanup");
        SendBirdConfig.sb.disconnect(function () {
            console.log("A current user is discconected from SendBird server")
        });
        SendBirdConfig.sb.removeChannelHandler(
            this.state.currentUser.userId + utils.UUID
        );
    }
    componentWillUnmount() {
        this.componentCleanup();
        window.removeEventListener("resize", this.updateWindowDimensions);
        window.removeEventListener("beforeunload", this.componentCleanup);
    }
    addMessageReceivedListener() {
        let channelHandler = new SendBirdConfig.sb.ChannelHandler();
        channelHandler.onMessageReceived = this.MessageReceived;
        channelHandler.onChannelChanged = this.ChannelChanged;
        channelHandler.onUserReceivedInvitation = this.UserReceivedInvitation;
        SendBirdConfig.sb.addChannelHandler(this.state.currentUser.userId + utils.UUID, channelHandler);
    }

    componentDidMount() {
        console.log("in componentDidMount");
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
        window.addEventListener("beforeunload", this.componentCleanup);
        this.addMessageReceivedListener();
        console.log(window.location.origin);
        var that = this;
        firebaseApp.onAuthStateChanged(function (user) {
            // user is undefined if no user signed in
            if (user) {
                getDirectory().then((response) => {
                    console.log(response);
                    that.getUsersList(response);
                }).catch((error) => { console.log(error); })
            }
            else {
                signIn();
            }
        });
    }
    // receives response from LCC messageHandlers i.e salesforce
    getUsersList(response) {
        // ****** changes Made here must also be made in onChannelChanged  and getUsersList and UserReceivedInvitation
        console.log("responseJSON in React");
        console.log(response)
        var directory = response.directory;
        console.log("directory");
        console.log(directory);
        var profile_url = '';

        directory.forEach(user => {
            profile_url = getProfileURL(user.profile_url);
            utils.directoryMap.set(user.user_id, {
                name: user.nickname,
                imgsrc: profile_url,
                userId: user.user_id
            })
        })
        //removing current logged user id from directory map
        utils.directoryMap.delete(response.currentUserID)
        console.log("direcorty from Response Map")
        console.log(utils.directoryMap)

        SendBirdConfig.sb
            .connect(response.currentUserID)
            .then(result => {
                this.setState({
                    currentUser: result,
                    fmgName: response.fmgName,
                    name: response.name
                });
                console.log(this.state.currentUser);
                Notification.requestPermission().then(permission => {
                    if (permission === 'granted') {
                        console.log('perimissions granted')
                    } else {
                        console.log('Unable to get permission to notify.');
                    }
                })
            })
            .catch(error => {
                console.log("got error " + error);
            });

        var groupMap = new Map();

        var inboxMap = new Map();
        const that = this;


        var channelListQuery = SendBirdConfig.sb.GroupChannel.createMyGroupChannelListQuery();
        channelListQuery.includeEmpty = false;
        channelListQuery.order = "latest_last_message"; // 'chronological', 'latest_last_message', 'channel_name_alphabetical', and 'metadata_value_alphabetical'
        channelListQuery.limit = 100; // The value of pagination limit could be set up to 100.
        if (channelListQuery.hasNext) {
            channelListQuery.next().then(result => {
                console.log('result');
                console.log(result);
                result.forEach((item) => {

                    console.log("JSON PARSE")
                    console.log(JSON.parse(item.data))

                    var isGroup = !item.isDistinct;
                    console.log(isGroup);

                    var peerUserIds = Object.keys(item.memberMap);
                    console.log(peerUserIds);

                    peerUserIds = peerUserIds.filter(
                        peerUserId => peerUserId !== response.currentUserID
                    );
                    console.log(peerUserIds);

                    var name = utils.getChannelName(isGroup, item.name, peerUserIds, this.state.currentUser.userId);
                    console.log(name);

                    var lastMessage = utils.getLastMessage(item, this.state.currentUser.userId, isGroup);
                    console.log(lastMessage);

                    var imgsrc = peerUserIds.length === 1 ? getProfileURL(utils.directoryMap.get(peerUserIds[0]).imgsrc) :
                        item.coverUrl;
                    console.log('imgsrc: ' + imgsrc);
                    console.log('item.customType: ' + item.customType === "");

                    if (item.customType !== "group") {
                        console.log("Inbox map prep")
                        inboxMap.set(item.url, {
                            name: name,
                            peerUserIds: peerUserIds,
                            lastMessage: lastMessage,
                            channel: item.url,
                            isGroup: isGroup,
                            time: item.lastMessage !== null ? item.lastMessage.createdAt : 0,
                            unread: item.unreadMessageCount,
                            imgsrc: imgsrc,
                            pin: JSON.parse(item.data).pin
                        });
                        console.log(" inboxMap")
                        console.log(inboxMap);
                    } else if (item.customType === "group") {
                        console.log("group Channel");
                        groupMap.set(item.url, {
                            name: name,
                            peerUserIds: peerUserIds,
                            imgsrc: imgsrc,
                            lastMessage: lastMessage,
                            channel: item.url,
                            isGroup: isGroup,
                            unread: item.unreadMessageCount,
                            time: item.lastMessage !== null ? item.lastMessage.createdAt : 0,
                            pin: JSON.parse(item.data).pin
                        })
                        console.log("GROUPMAP");
                        console.log(groupMap);
                    }
                })
                console.log("Setting state after Preping Of Maps")
                that.setState({
                    inboxMap: inboxMap,
                    currentMap: new Map([...inboxMap, ...groupMap]),
                    filteredMap: new Map([...inboxMap, ...groupMap]),
                    directoryMap: utils.directoryMap,
                    groupMap: groupMap,
                    isloading: false
                })
            });
        }



    } // getUsersList End


    filter(searchValue) {
        console.log("filter" + searchValue);
        let newMap = new Map(this.state.currentMap);
        function logMapElements(value, key, map) {
            if (!value.name.toLowerCase().includes(searchValue.toLowerCase()))
                newMap.delete(key)
        }
        // If the search bar isn't empty
        if (searchValue !== "") {
            // Assign the original list to currentList
            this.state.currentMap.forEach(logMapElements);
        }
        console.log(newMap);
        this.setState({
            filteredMap: newMap,
            value: searchValue
        });
        // Set the filtered state based on what our rules added to newList
    }

    handleChange(event) {
        // Set the filtered state based on what our rules added to newList
        this.filter(event.target.value)
    }

    closeNewMessage() {
        if (this.state.newChat) {
            this.setState({
                tabIndex: 0,
                currentMap: new Map([...this.state.inboxMap, ...this.state.groupMap]),
                filteredMap: new Map([...this.state.inboxMap, ...this.state.groupMap]),
                newChat: false
            }, this.filter(""))
        }
    }
    openNewGroup(channel) {
        console.log("openNewGroup")
        console.log(channel)
        this.setState({
            channel: channel.url,
            newGroup: false
        })
    }

    inbox() {
        console.log("inbox()")
        console.log(this.state.filteredMap.values());
        var pinDividerDisplayed = false;
        return (
            <Scrollbars
                style={{
                    height: this.state.height,
                    //backgroundColor: "rgb(0,0,0,.05)"
                }}
            >
                {
                    _.orderBy(Array.from(this.state.filteredMap.values()), ["pin", "time"], ['desc', 'desc']).map((item, index) => (
                        <div style={{
                            backgroundColor: this.state.channel === item.channel ? "rgb(0,0,0,.05)" : "",
                            /* top-left | top-right | bottom-right | bottom-left */
                            borderRadius: "20px 0 0px 20px"
                        }} >
                            {(!pinDividerDisplayed && item.pin !== "true") && <Divider />}
                            {(!pinDividerDisplayed && item.pin !== "true") && (pinDividerDisplayed = true)}
                            {this.state.width > 920 && <items.CustomListItem key={index} item={item} onClick={() => {
                                this.setState({ channel: item.channel })
                            }} searchTerm={this.state.value} />}
                            {this.state.width < 920 && <items.SmallCustomListItem key={index} item={item} onClick={() => {
                                this.setState({ channel: item.channel })
                            }} searchTerm={this.state.value} />}
                        </div>
                    ))}
            </Scrollbars>)
    }
    directory() {
        console.log("directory()")
        console.log(this.state.filteredMap.values());
        return (<Scrollbars
            style={{
                height: this.state.height,
                // backgroundColor: this.state.newChat ? "white" : "rgb(0,0,0,.05)"
            }}
        >
            {
                _.orderBy(Array.from(this.state.filteredMap.values()), ['name'], ['asc']).map((item, index) => (
                    <div >
                        <items.DirectoryItem key={index} item={item} onClick={() => {
                            var that = this;
                            var params = new SendBirdConfig.sb.GroupChannelParams();
                            params.isDistinct = true;
                            params.addUserIds([item.userId, this.state.currentUser.userId]);
                            params.operatorIds = [item.userId, this.state.currentUser.userId];
                            params.coverUrl = ""
                            params.data = JSON.stringify({
                                groupDescription: "",
                                pin: ""
                            });
                            SendBirdConfig.sb.GroupChannel.createChannel(
                                params,
                                function (sbGroupChannel, error) {
                                    if (error) {
                                        console.log(error);
                                    }
                                    console.log(sbGroupChannel);
                                    that.setState({ channel: sbGroupChannel.url }, that.closeNewMessage)
                                }
                            );
                        }} searchTerm={this.state.value} />

                    </div>
                ))}
        </Scrollbars>)
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isloading && < div style={{ position: 'relative', height: '5rem' }}>
                    <Spinner
                        size="small"
                        variant="base"
                        assistiveText={{ label: 'Main Frame Loading...' }}
                    />
                </div>}
                {
                    !this.state.isloading && <div>
                        <PrimarySearchAppBar filter={this.filter} name={this.state.name} fmgName={this.state.fmgName} />
                        < div style={{ /* height: !this.state.printMode ? this.state.height - 120 : "", */ "backgroundColor": "white" }
                        } class="slds-grid slds-wrap" >
                            {!this.state.printMode && <ul className="slds-col slds-border_right slds-no-print" style={{ width: "35%", "backgroundColor": "white", position: "relative" }}>
                                <div>
                                    <div class="slds-tabs_default" >    {/*previous backgroundColor rgb(0,0,0,.05)*/}
                                        <ul class="slds-tabs_default__nav slds-grid" role="tablist">
                                            <li onClick={() => {
                                                console.log("on Tab changed to 0")
                                                this.setState({
                                                    tabIndex: 0,
                                                    currentMap: new Map([...this.state.inboxMap, ...this.state.groupMap]),
                                                    filteredMap: new Map([...this.state.inboxMap, ...this.state.groupMap]),
                                                }, () => {
                                                    this.filter(this.state.value)
                                                })
                                            }} style={{ height: "73px" }} class={this.state.tabIndex === 0 ? "slds-tabs_default__item  slds-align_absolute-center".concat(" slds-is-active") : "slds-tabs_default__item slds-col slds-align_absolute-center"} >
                                                {this.state.width > 800 && <MeidcalUserChatIcon tabIndex={this.state.tabIndex} style={{ color: "rgb(10,132,255)" }} />}
                                                <div class="slds-tabs_default__link " style={{ fontWeight: "bold", paddingLeft: "5px" }}> Inbox </div>
                                            </li>
                                            <li onClick={() => {
                                                this.setState({
                                                    tabIndex: 1,
                                                    currentMap: new Map(this.state.directoryMap),
                                                    filteredMap: new Map(this.state.directoryMap),
                                                }, () => this.filter(this.state.value))
                                            }} style={{ height: "73px" }} class={this.state.tabIndex === 1 ? "slds-tabs_default__item  slds-align_absolute-center".concat(" slds-is-active") : "slds-tabs_default__item slds-col slds-align_absolute-center"} >
                                                {this.state.width > 800 && <AddressBookIcon tabIndex={this.state.tabIndex} style={{ color: "rgb(10,132,255)" }} />}
                                                <div class="slds-tabs_default__link" style={{ fontWeight: "bold" }}> Directory </div>
                                            </li>
                                            <li onClick={() => {
                                                this.setState({
                                                    tabIndex: 2,
                                                    currentMap: new Map(this.state.groupMap),
                                                    filteredMap: new Map(this.state.groupMap),
                                                }, () => this.filter(this.state.value))
                                            }} style={{ height: "73px" }} class={this.state.tabIndex === 2 ? "slds-tabs_default__item  slds-align_absolute-center".concat(" slds-is-active") : "slds-tabs_default__item slds-col slds-align_absolute-center"} >
                                                {this.state.width > 800 && <MedicalGroupsIcon tabIndex={this.state.tabIndex} style={{ color: "rgb(10,132,255)" }} />}
                                                <div style={{ fontWeight: "bold", paddingLeft: "2px" }} class="slds-tabs_default__link" > Groups </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div style={{
                                        backgroundColor: "white", border: "solid transparent", borderRadius: "200px"
                                    }} >
                                        <LeftPanelActions width={this.state.width} value={this.state.value} setSort={(sortBy) => { this.setState({ sortBy: sortBy }) }}
                                            handleChange={this.handleChange} tabIndex={this.state.tabIndex}
                                            renderNewMessage={() => {
                                                console.log("renderNewMessage")
                                                this.setState({
                                                    newChat: true,
                                                }, () => this.filter(this.state.value))
                                            }}

                                            newGroup={() => {
                                                this.setState({
                                                    newGroup: true, tabIndex: 2,
                                                    currentMap: this.state.groupMap
                                                }, () => { this.filter(this.state.value) })
                                            }} />

                                    </div>
                                </div>

                                {this.state.tabIndex === 0 && this.inbox()}
                                {this.state.tabIndex === 1 && this.directory()}
                                {this.state.tabIndex === 2 && this.inbox()}
                            </ul>}
                            <div className="slds-col " style={{ width: "65%" }}>
                                {this.state.channel !== "" &&
                                    < MessageBoard key="3"
                                        loggedUser={this.state.currentUser}
                                        channel={this.state.channel}
                                        directoryMap={this.state.directoryMap}
                                        setPrintMode={() => {
                                            this.setState({ printMode: !this.state.printMode });
                                        }} printMode={this.state.printMode} />
                                }
                            </div>
                            {this.state.newGroup && <NewGroup
                                close={() => {
                                    this.setState({
                                        newGroup: false
                                    })
                                }}
                                newGroup={this.state.newGroup}
                                groupName=""
                                groupDescription=""
                                members={new Set()}
                                options={[]}
                                directoryMap={this.state.directoryMap}
                                openNewGroup={this.openNewGroup}
                            />}
                            {this.state.newChat && <NewMessage newChat={this.state.newChat}
                                closeNewMessage={this.closeNewMessage} createNewDirectGroup={this.createNewDirectGroup}
                                inboxMap={this.state.inboxMap} />}
                        </div>
                    </div >
                }
            </React.Fragment >

        );
    }

    createNewDirectGroup(members) {
        if (members.length > 0) {
            var that = this;
            var params = new SendBirdConfig.sb.GroupChannelParams();
            params.isDistinct = true;
            params.addUserIds(members);
            params.operatorIds = members;
            params.coverUrl = ""
            params.data = JSON.stringify({
                groupDescription: "",
                pin: ""
            });
            SendBirdConfig.sb.GroupChannel.createChannel(
                params,
                function (groupChannel, error) {
                    if (error) {
                        console.log(error);
                    }
                    console.log('groupChannel');
                    console.log(groupChannel);
                    if ((new Date().getTime() - 120000) < groupChannel.createdAt && members.length > 1) {
                        const params = new SendBirdConfig.sb.UserMessageParams();

                        params.customType = "Event";
                        params.message = "joined this group";
                        groupChannel.sendUserMessage(params, function (message, error) {
                            if (error) {
                                console.log(error);
                                return;
                            }
                            console.log("sendMessageToSB in createGroup");
                            console.log(message);
                        });
                        var temp = [];
                        members.forEach(membersID => {
                            membersID !== that.state.currentUser.userId &&
                                temp.push(utils.directoryMap.get(membersID).name);
                        });
                        var name = temp.join();
                        params.message = "added " + name + " to this group";
                        groupChannel.sendUserMessage(params, function (message, error) {
                            if (error) {
                                console.log(error);
                                return;
                            }
                            console.log("sendMessageToSB");
                            console.log(message);
                        });
                    }
                    that.setState({ channel: groupChannel.url }, that.closeNewMessage)
                }
            );
        }
    }
}


export function LeftPanelActions(props) {
    return (
        <div style={{
            height: "40px"
        }} className="slds-grid slds-grid_align-spread">

            <SearchIcon
                className="slds-col"
                style={{
                    color: "rgb(10,132,255)",
                    margin: "10px",
                    cursor: "pointer"
                }}
            />
            <input className="slds-col" value={props.value} onChange={props.handleChange}
                style={{ paddingLeft: "0px", paddingRight: "0px", border: "none", outline: 0, width: props.width - 800 }} placeholder="Search"></input>
            <Popover
                ariaLabelledby="ALTERNATIVE-HEADING"
                body={<ul class="slds-dropdown__list" role="menu" aria-label="Show More">
                    <li style={{ cursor: "pointer", marginBottom: "10px" }} class="slds-dropdown__item" role="presentation" onClick={props.renderNewMessage}>
                        <span class="slds-truncate" title="New Message">New Message</span>
                    </li>
                    <li style={{ cursor: "pointer" }} class="slds-dropdown__item" role="presentation" onClick={props.newGroup}>
                        <span class="slds-truncate" title="New Group">New Group</span>
                    </li>
                </ul>}
                hasNoCloseButton={true}
                align="bottom"
                style={{ width: "135px" }}
            >
                <div className="slds-col" style={{ outline: "none" }}>
                    <NewMessageIcon1
                        style={{
                            color: "rgb(10,132,255)",
                            cursor: "pointer",
                            marginTop: "5px",
                            outline: "none"
                        }} />
                </div>
            </Popover>
        </div >
    );
}

export function MeidcalUserChatIcon(props) {
    return (
        <svg style={props.style} width="35px" height="20px" class="svg-inline--fa fa-address-book fa-w-14" role="img" viewBox="0 0 448 512">
            {props.tabIndex === 0 ? <path fill="currentColor" d="M512 0c-70.69 0-128 50.14-128 112 0 28.76 12.75 54.72 33.11 74.55a312.08 312.08 0 0 1-31.29 55.37 9.86 9.86 0 0 0-1.25 9.07c1.09 3.13 3.43 5 6.11 5 39.84 0 72.35-17.14 95.22-34.36A146 146 0 0 0 512 224c70.69 0 128-50.14 128-112S582.69 0 512 0zM224 288A144 144 0 1 0 80 144a144 144 0 0 0 144 144zm0-240a96 96 0 1 1-96 96 96.15 96.15 0 0 1 96-96zm89.6 256c-11 0-21.8 2.6-32.2 6.2a173 173 0 0 1-114.8 0c-10.4-3.6-21.2-6.2-32.2-6.2A134.43 134.43 0 0 0 0 438.4V488a24 24 0 0 0 48 0v-49.6c0-45.5 35.4-82.4 80-85.8v50a56 56 0 1 0 32 0v-44.7c20.8 6.3 42.3 10.1 64 10.1s43.2-3.8 64-10.1v36.3c-28.2 7.5-48 34.5-48 64.6V488a16.06 16.06 0 0 0 4.7 11.3l10.3 10.3a8 8 0 0 0 11.3 0l11.3-11.3a8 8 0 0 0 0-11.3l-5.7-5.7V456a32.14 32.14 0 0 1 37.4-31.6c15.7 2.6 26.6 17.4 26.6 33.3v23.6l-5.7 5.7a8 8 0 0 0 0 11.3l11.3 11.3a8 8 0 0 0 11.3 0l10.3-10.3a16.06 16.06 0 0 0 4.7-11.3v-32a63.8 63.8 0 0 0-48-61.6v-41.7c44.6 3.3 80 40.3 80 85.8V488a24 24 0 0 0 48 0v-49.6c.2-74.2-60-134.4-134.2-134.4zM168 456a24 24 0 1 1-24-24 23.94 23.94 0 0 1 24 24z"></path>
                : <path fill="currentColor" d="M512 0c-70.69 0-128 50.17-128 112 0 28.76 12.75 54.72 33.11 74.55a312.08 312.08 0 0 1-31.29 55.37 9.86 9.86 0 0 0-1.25 9.07c1.09 3.13 3.43 5 6.1 5 39.85 0 72.35-17.13 95.23-34.36A146 146 0 0 0 512 224c70.7 0 128-50.14 128-112S582.7 0 512 0zm0 192a114.76 114.76 0 0 1-20.38-1.84l-13.78-2.5-11.18 8.42a155.47 155.47 0 0 1-26.13 16.07c2-4.08 4-8.35 5.91-12.79l8.87-20.28-15.87-15.45C428.75 153.24 416 135.83 416 112c0-44.11 43.06-80 96-80s96 35.89 96 80-43.06 80-96 80zm-194.31 96.1c-33.8-1-44.3 15.9-93.7 15.9s-59.79-16.9-93.59-15.9A134.33 134.33 0 0 0 0 422.4V504a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8v-81.6c0-54.3 42.6-98.4 96-101.8v81.7a56 56 0 1 0 32 0v-76.9a200.3 200.3 0 0 0 128 0v68.8c-28.2 7.5-48 34.5-48 64.6V488a16.06 16.06 0 0 0 4.7 11.3l10.3 10.3a8 8 0 0 0 11.3 0l11.3-11.3a8 8 0 0 0 0-11.3l-5.7-5.7V456a32.14 32.14 0 0 1 37.4-31.6c15.7 2.6 26.6 17.4 26.6 33.3v23.6l-5.7 5.7a8 8 0 0 0 0 11.3l11.3 11.3a8 8 0 0 0 11.3 0l10.3-10.3a16.06 16.06 0 0 0 4.7-11.3v-32a63.8 63.8 0 0 0-48-61.6v-73.7c53.4 3.4 96 47.5 96 101.8V504a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8v-81.6a134 134 0 0 0-130.11-134.3zM168 456a24 24 0 1 1-24-24 24.06 24.06 0 0 1 24 24zm56-200A128 128 0 1 0 96 128a128 128 0 0 0 128 128zm0-224a96 96 0 1 1-96 96 96.15 96.15 0 0 1 96-96z"></path>
            }
        </svg>
    );
}

export function AddressBookIcon(props) {
    return (
        <svg style={props.style} width="35px" height="20px" class="svg-inline--fa fa-address-book fa-w-14" role="img" viewBox="0 0 448 512">
            {props.tabIndex === 1 ? <path fill="currentColor" d="M436 160c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h320c26.5 0 48-21.5 48-48v-48h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20zm-68 304H48V48h320v416zM208 256c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm-89.6 128h179.2c12.4 0 22.4-8.6 22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6-10.8 0-18.7 8-44.8 8-26.9 0-33.4-8-44.8-8-37.1 0-67.2 25.8-67.2 57.6v19.2c0 10.6 10 19.2 22.4 19.2z"></path>
                : <path fill="currentColor" d="M436 160c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64v384c0 35.3 28.7 64 64 64h288c35.3 0 64-28.7 64-64v-32h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20zm-52 288c0 17.6-14.4 32-32 32H64c-17.6 0-32-14.4-32-32V64c0-17.6 14.4-32 32-32h288c17.6 0 32 14.4 32 32v384zM208 272c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm46.8 144c-19.5 0-24.4 7-46.8 7s-27.3-7-46.8-7c-21.2 0-41.8 9.4-53.8 27.4C100.2 326.1 96 339 96 352.9V392c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-39.1c0-7 2.1-13.8 6-19.6 5.6-8.3 15.8-13.2 27.3-13.2 12.4 0 20.8 7 46.8 7 25.9 0 34.3-7 46.8-7 11.5 0 21.7 5 27.3 13.2 3.9 5.8 6 12.6 6 19.6V392c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-39.1c0-13.9-4.2-26.8-11.4-37.5-12.3-18-32.9-27.4-54-27.4z">
                </path>
            }
        </svg>
    );
}
export function NewMessageIcon(props) {
    return (
        <svg style={props.style} width="35px" height="20px" class="svg-inline--fa fa-address-book fa-w-14" role="img" viewBox="0 0 448 512">
            <path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path>
        </svg>
    );
}
export function NewMessageIcon1(props) {
    return (
        <svg style={props.style} width="35px" height="20px" class="svg-inline--fa fa-address-book fa-w-14" role="img" viewBox="0 0 448 512">
            <path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path>
        </svg>
    );
}



export function MedicalGroupsIcon(props) {
    return (
        <svg style={props.style} width="35px" height="20px" class="svg-inline--fa fa-address-book fa-w-14" role="img" viewBox="0 0 448 512">
            {props.tabIndex === 2 ? <path fill="currentColor" d="M512 224a128 128 0 1 0 128 128 128 128 0 0 0-128-128zm64 144a5.33 5.33 0 0 1-5.33 5.33h-37.34v37.34A5.33 5.33 0 0 1 528 416h-32a5.33 5.33 0 0 1-5.33-5.33v-37.34h-37.34A5.33 5.33 0 0 1 448 368v-32a5.33 5.33 0 0 1 5.33-5.33h37.34v-37.34A5.33 5.33 0 0 1 496 288h32a5.33 5.33 0 0 1 5.33 5.33v37.34h37.34A5.33 5.33 0 0 1 576 336zM320 256a112 112 0 1 0-112-112 111.94 111.94 0 0 0 112 112zm0-176a64 64 0 1 1-64 64 64.06 64.06 0 0 1 64-64zM96 224a80 80 0 1 0-80-80 80 80 0 0 0 80 80zm0-112a32 32 0 1 1-32 32 32.09 32.09 0 0 1 32-32zm278.26 320H176v-44.8a67.38 67.38 0 0 1 65.4-67.2 203.8 203.8 0 0 0 78.6 16 198.4 198.4 0 0 0 33.94-3.14 157.56 157.56 0 0 1 16-52.84c-1.76.45-3.56.65-5.3 1.18a152.46 152.46 0 0 1-89.4 0c-47.1-14.3-100.3.8-127.8 41.7a114.59 114.59 0 0 0-19.6 64.3V432a48 48 0 0 0 48 48H417a160.27 160.27 0 0 1-42.74-48zM154.8 270.3A83.7 83.7 0 0 0 108 256H84a84.12 84.12 0 0 0-84 84 24 24 0 0 0 48 0 36.11 36.11 0 0 1 36-36h24a35.48 35.48 0 0 1 12.3 2.5 148.37 148.37 0 0 1 34.5-36.2z"></path>
                : <path fill="currentColor" d="M512 224a128 128 0 1 0 128 128 128 128 0 0 0-128-128zm0 224a96 96 0 1 1 96-96 96 96 0 0 1-96 96zm58.67-117.33h-37.34v-37.34A5.33 5.33 0 0 0 528 288h-32a5.33 5.33 0 0 0-5.33 5.33v37.34h-37.34A5.33 5.33 0 0 0 448 336v32a5.33 5.33 0 0 0 5.33 5.33h37.34v37.34A5.33 5.33 0 0 0 496 416h32a5.33 5.33 0 0 0 5.33-5.33v-37.34h37.34A5.33 5.33 0 0 0 576 368v-32a5.33 5.33 0 0 0-5.33-5.33zM320 256a112 112 0 1 0-112-112 111.94 111.94 0 0 0 112 112zm0-192a80 80 0 1 1-80 80 80.11 80.11 0 0 1 80-80zM96 224a80 80 0 1 0-80-80 80 80 0 0 0 80 80zm0-128a48 48 0 1 1-48 48 48 48 0 0 1 48-48zm288.81 352H176a16 16 0 0 1-16-16v-44.8a83.08 83.08 0 0 1 14.1-46.4c13.8-20.5 38.4-32.8 65.7-32.8s37.2 12 80.2 12a164.08 164.08 0 0 0 36.33-3.66A157.66 157.66 0 0 1 370 279.85c-13.87 3.51-26.11 8.15-50 8.15-38.4 0-46.7-12-80.1-12-36.3 0-71.6 16.2-92.3 46.9a114.59 114.59 0 0 0-19.6 64.3V432a48 48 0 0 0 48 48h241a161 161 0 0 1-32.19-32zM157.1 268.9A72.83 72.83 0 0 0 116 256H76c-41.9 0-76 35.9-76 80a16 16 0 0 0 32 0c0-26.5 19.8-48 44-48h40a39.79 39.79 0 0 1 15.7 3.3 138.76 138.76 0 0 1 25.4-22.4z"></path>
            }

        </svg >
    );
}

export default App;