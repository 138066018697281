import React from "react";
import _ from "lodash";
import * as items from "./Items.jsx";
import { format } from "date-fns";
import ProgressRing from "@salesforce/design-system-react/components/progress-ring";

class MessageDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      readMemberMap: new Map()
    };
  }
  componentDidMount() {
    var messageType = "MESG";
    if (this.props.messageDetailsType === "file") messageType = "FILE";
    var that = this;
    this.props.groupChannel.getPreviousMessagesByID(
      this.props.messageDetailsId,
      true,
      0,
      false,
      messageType,
      "",
      function(messages, error) {
        if (error) {
          return;
        }
        console.log(messages);
        var readMembers = that.props.groupChannel.getReadMembers(messages[0]);
        console.log(that.props.groupChannel.getReadStatus(messages[0]));
        console.log("readMembers");
        console.log(readMembers);
        var readMembersMap = new Map();
        var lastSeenAt = 0;
        readMembers.forEach(user => {
          console.log(user);
          if (user.lastSeenAt === 0) {
            lastSeenAt = that.props.groupChannel.getReadStatus(messages[0])[
              user.userId
            ].last_seen_at;
          } else {
            lastSeenAt = user.lastSeenAt;
          }
          readMembersMap.set(user.userId, {
            lastSeenAt: lastSeenAt,
            name: user.nickname,
            imgsrc: user.profileUrl,
            userId: user.userId
          });
        });

        that.setState({ readMemberMap: readMembersMap });
        // A list of messages, the IDs of which are ahead of the specified message ID, is successfully retrieved.
      }
    );
  }

  render() {
    return (
      <div className="slds-align_absolute-center slds-grid slds-grid_vertical">
        <div style={{ margin: "10px 0px 0px 0px" }}>
          <span>
            {this.state.readMemberMap.size}/{this.props.totalCount} Read
          </span>
        </div>
        <div style={{ margin: "5px 0px 5px 0px" }}>
          <ProgressRing
            value={
              (this.state.readMemberMap.size * 100) / this.props.totalCount
            }
          />
        </div>
        <ul>
          {_.orderBy(
            Array.from(this.state.readMemberMap.values()),
            ["name"],
            ["asc"]
          ).map((item, index) => (
            <div className="slds-grid slds-grid_align-spread  slds-border_bottom">
              <items.DirectoryItem
                className="slds-col"
                key={index}
                item={item}
              />
              <div style={{ marginTop: "25px", marginBottom: "25px" }}>
                Read At: {format(item.lastSeenAt, "hh:mm a")}{" "}
              </div>
            </div>
          ))}
        </ul>
      </div>
    );
  }
}
export default MessageDetails;
