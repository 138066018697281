import React from "react";
import Avatar from "@salesforce/design-system-react/components/avatar";
import { format } from "date-fns";
import Button from "@salesforce/design-system-react/components/button";
import {
  getHighlightedText,
  getProfileURL,
  GroupChatIcon,
  UserChatIcon
} from "./Utils/IntivaUtils.jsx";
import * as utils from "./Utils/utils.ts";
export const CustomListItem = props => (
  <li
    style={{ height: "64px", position: "relative", cursor: "pointer" }}
    role="row"
  >
    <div
      style={{
        borderRadius: "10px",
        minWidth: "0",
        paddingLeft: "5px",
        paddingRight: "5px",
        paddingTop: "15px"
      }}
      className="slds-grid slds-wrap"
      onClick={props.onClick}
      role="gridcell"
    >
      <div
        style={{ paddingRight: "5px", position: "relative" }}
        className="slds-col slds-size_9-of-12"
      >
        <div className="slds-grid ">
          {// if this is NOT a Group
          !props.item.isGroup &&
            (props.item.peerUserIds.length === 1 ? (
              <Avatar
                variant="entity"
                label={props.item.name}
                imgSrc={getProfileURL(
                  utils.directoryMap.get(props.item.peerUserIds[0]).imgsrc
                )}
              />
            ) : (
              <UserChatIcon
                style={{
                  width: "2rem",
                  height: "2rem",
                  backgroundColor: "#7e8be4",
                  borderRadius: "7px",
                  color: "white",
                  paddingTop: "5px",
                  paddingBottom: "5px"
                }}
              />
            ))}
          {// if this is a Group
          props.item.isGroup &&
            (props.item.imgsrc === "" ? (
              <GroupChatIcon
                style={{
                  width: "2rem",
                  height: "2rem",
                  backgroundColor: "#7e8be4",
                  borderRadius: "7px",
                  color: "white",
                  paddingTop: "5px",
                  paddingBottom: "5px"
                }}
              />
            ) : (
              <Avatar
                label={props.item.name}
                imgSrc={props.item.imgsrc}
                variant="entity"
              />
            ))}

          {props.item.online && (
            <span
              style={{
                height: "15px",
                width: "15px",
                backgroundColor: "#4bcc1f",
                borderStyle: "solid",
                borderColor: "white",
                borderRadius: "50%",
                display: "inline-block",
                bottom: "0px",
                right: "5px",
                position: "absolute"
              }}
            >
              {" "}
            </span>
          )}
          <div
            style={{ paddingLeft: "5px" }}
            className="slds-grid_vertical slds-size_9-of-12"
          >
            <div
              style={{
                color: "rgba(0, 0, 0, 1)",
                fontSize: "15px",
                fontWeight: 400,
                lineHeight: 1.4
              }}
              className="slds-truncate"
            >
              {props.searchTerm && props.searchTerm !== ""
                ? getHighlightedText(props.item.name, props.searchTerm)
                : props.item.name}
            </div>
            {!(props.item.lastMessage === undefined) && (
              <div
                style={{
                  color: "rgba(0, 0, 0, .40)",
                  fontSize: "12px",
                  fontWeight: 400,
                  height: "18px"
                }}
                className="slds-truncate"
              >
                {props.item.lastMessage.message}{" "}
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        style={{ paddingTop: "0px", paddingLeft: "0px" }}
        className="slds-col slds-size_3-of-12"
      >
        <div className="slds-grid_vertical-align-start">
          {!(props.item.lastMessage === undefined) && (
            <div
              style={{
                color: "rgba(0, 0, 0, .40)",
                fontSize: "12px",
                fontWeight: 400
              }}
              className="slds-truncate"
            >
              {props.item.lastMessage.time &&
                format(Date.now(), "MM/dd/yyyy") !==
                  format(props.item.lastMessage.time, "MM/dd/yyyy") &&
                format(props.item.lastMessage.time, "MM/dd/yyyy")}

              {props.item.lastMessage.time &&
                format(Date.now(), "MM/dd/yyyy") ===
                  format(props.item.lastMessage.time, "MM/dd/yyyy") &&
                format(props.item.lastMessage.time, "hh:mm a")}
            </div>
          )}
          <div>
            {props.item.unread > 0 && (
              <span style={{ paddingTop: "20px", paddingRight: "10px" }}>
                <span
                  style={{
                    backgroundColor: props.item.priority
                      ? "red"
                      : "rgb(10,132,255)",
                    borderRadius: "8rem",
                    padding: "3px 8px",
                    color: "white",
                    fontSize: "11px",
                    lineHeight: 1.4
                  }}
                >
                  {"" + props.item.unread}
                </span>
              </span>
            )}
            {props.item.pin === "true" && (
              <span>
                <Button
                  style={{
                    backgroundColor: "rgb(199,199,204)",
                    borderRadius: "2rem",
                    color: "white"
                  }}
                  iconPath="symbols.svg#pinned"
                  iconSize="small"
                  iconVariant="bare"
                  variant="icon"
                />
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  </li>
);

export const SmallCustomListItem = props => (
  <li
    style={{ height: "64px", position: "relative", cursor: "pointer" }}
    role="row"
  >
    <div
      style={{
        borderRadius: "10px",
        minWidth: "0",
        paddingLeft: "15px",
        paddingRight: "8px",
        paddingTop: "15px"
      }}
      className="slds-grid "
      onClick={props.onClick}
      role="gridcell"
    >
      <div style={{ paddingRight: "10px", position: "relative" }}>
        <Avatar
          label={props.item.name}
          imgSrc={props.item.imgsrc}
          variant="entity"
          size="large"
        />
        {props.item.online && (
          <span
            style={{
              height: "15px",
              width: "15px",
              backgroundColor: "#4bcc1f",
              borderStyle: "solid",
              borderColor: "white",
              borderRadius: "50%",
              display: "inline-block",
              bottom: "0px",
              right: "5px",
              position: "absolute"
            }}
          >
            {" "}
          </span>
        )}
      </div>
      <div className="slds-grid slds-grid_vertical ">
        <div
          style={{
            color: "rgba(0, 0, 0, 1)",
            fontSize: "15px",
            fontWeight: 400,
            lineHeight: 1.4
          }}
        >
          {props.searchTerm && props.searchTerm !== ""
            ? getHighlightedText(props.item.name, props.searchTerm)
            : props.item.name}
        </div>
      </div>
      {props.item.unread > 0 && (
        <span style={{ paddingLeft: "30px", paddingTop: "20px" }}>
          <span
            style={{
              backgroundColor: props.item.priority ? "red" : "rgb(10,132,255)",
              borderRadius: "8rem",
              padding: "3px 8px",
              color: "white",
              fontSize: "11px",
              lineHeight: 1.4
            }}
          >
            {"" + props.item.unread}
          </span>
        </span>
      )}
    </div>
  </li>
);

export const DirectoryItem = props => (
  <li
    style={{ height: "64px", position: "relative", cursor: "pointer" }}
    role="row"
  >
    <div
      style={{
        borderRadius: "10px",
        minWidth: "0",
        paddingLeft: "15px",
        paddingRight: "8px",
        paddingTop: "15px"
      }}
      className="slds-grid "
      onClick={props.onClick}
      role="gridcell"
    >
      <div style={{ paddingRight: "10px", position: "relative" }}>
        <Avatar
          label={props.item.name}
          imgSrc={props.item.imgsrc}
          variant="entity"
        />
        {props.item.online && (
          <span
            style={{
              height: "15px",
              width: "15px",
              backgroundColor: "#4bcc1f",
              borderStyle: "solid",
              borderColor: "white",
              borderRadius: "50%",
              display: "inline-block",
              bottom: "0px",
              right: "5px",
              position: "absolute"
            }}
          >
            {" "}
          </span>
        )}
      </div>

      <div
        style={{
          color: "rgba(0, 0, 0, 1)",
          fontSize: "15px",
          fontWeight: 400,
          lineHeight: 1.4,
          paddingTop: "10px"
        }}
      >
        {props.searchTerm && props.searchTerm !== ""
          ? getHighlightedText(props.item.name, props.searchTerm)
          : props.item.name}
      </div>
    </div>
  </li>
);
