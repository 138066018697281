import React, { Component } from "react";
export default class FileInput extends Component {
  render() {
    if (
      this.props.fileMime === "application/pdf" ||
      this.props.fileMime === "application/msword"
    ) {
      return (
        <iframe
          src={
            "http://docs.google.com/viewer?url=" +
            this.props.fileURL +
            "&embedded=true"
          }
          title={this.props.fileName}
        >
          {this.props.fileName}
        </iframe>
      );
    }
    if (
      this.props.fileMime === "image/jpeg" ||
      this.props.fileMime === "image/png"
    ) {
      return (
        <a href={this.props.fileURL}>
          <img
            src={this.props.fileURL}
            alt={this.props.fileName}
            width="320"
            height="240"
          ></img>
        </a>
      );
    }

    if (this.props.fileMime === "video/mp4") {
      return (
        <a href={this.props.fileURL}>
          <video width="320" height="240" controls>
            <source src={this.props.fileURL} type="video/mp4" />
            {this.props.fileName}
          </video>
        </a>
      );
    }
    return (
      <a href={this.props.fileURL}>
        <span> {this.props.fileName}</span>
      </a>
    );
  }
}
