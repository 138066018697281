import React from "react";
import Card from "@salesforce/design-system-react/components/card";
import Button from "@salesforce/design-system-react/components/button";
import { format } from "date-fns";

class PrintMode extends React.Component {
  render() {
    console.log("this.props.listMessages");
    console.log(this.props.listMessages);
    return (
      <div>
        <Card
          className="slds-no-print"
          hasNoHeader={true}
          style={{
            height: "50px",
            backgroundColor: "white"
          }}
        >
          <div className="slds-align_absolute-center ">
            <div
              style={{ left: "0px", postion: "absolute" }}
              className="slds-text-heading_small slds-truncate_container_75"
            >
              <b className="slds-truncate">{this.props.Name}</b>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              top: "10px",
              right: "16px"
            }}
          >
            <Button
              onClick={() => {
                window.print();
              }}
              label="Print"
              variant="brand"
            />
            <Button onClick={this.props.setPrintMode} label="close" />
          </div>
        </Card>
        <div
          style={{
            height: "10px",
            backgroundColor: "rgb(0,0,0,.05)"
          }}
        ></div>
        <div className="slds-grid">
          <div
            className="slds-col slds-size_3-of-12 slds-no-print"
            style={{ backgroundColor: "rgb(0,0,0,.05)" }}
          ></div>
          <ul
            style={{
              backgroundColor: "white",
              minHeight: "100vh",
              margin: "25px 10px 25px 10px"
            }}
            className="slds-col"
          >
            {this.props.listMessages.map((item, index) => {
              return (
                <li
                  style={{
                    margin: "5px",
                    minHeight: "60px",
                    backgroundColor: index % 2 === 0 ? "" : "rgb(0,0,0,.05)"
                  }}
                >
                  <div
                    style={{
                      fontWeight: "500",
                      margin: "0.125rem 0.5rem 0 0.5rem"
                    }}
                  >
                    {item.nickName}
                  </div>
                  <div className="slds-chat-message__body">
                    <div style={{ margin: "0.125rem 0 0 0.5rem" }}>
                      {item.messageType === "file" ? (
                        <object data={item.fileURL} width="320" height="240">
                          {item.fileName}
                        </object>
                      ) : (
                        item.message
                      )}
                    </div>
                    <div
                      style={{
                        margin: "0 0 0 0.5rem",
                        color: "#3e3e3c",
                        fontSize: "0.625rem"
                      }}
                    >
                      {format(item.createdAt, "hh:mm a")}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
          <div
            className="slds-col slds-size_3-of-12 slds-no-print"
            style={{ backgroundColor: "rgb(0,0,0,.05)" }}
          ></div>
        </div>
      </div>
    );
  }
}

export function UserChatIcon(props) {
  return (
    <svg
      style={props.style}
      class="svg-inline--fa fa-address-book fa-w-14"
      role="img"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
      ></path>
    </svg>
  );
}
export default PrintMode;
