import React from "react";
import Combobox from "@salesforce/design-system-react/components/combobox";
import comboboxFilterAndLimit from "@salesforce/design-system-react/components/combobox/filter";
import IconSettings from "@salesforce/design-system-react/components/icon-settings";
import Modal from "@salesforce/design-system-react/components/modal";
import Button from "@salesforce/design-system-react/components/button/";
import * as utils from "./Utils/utils.ts";
import _ from "lodash";
import { format } from "date-fns";
import Avatar from "@salesforce/design-system-react/components/avatar";
import { GroupChatIcon } from "./Utils/IntivaUtils.jsx";

class NewMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      selection: [],
      optionsMap: []
    };
  }
  componentDidMount() {
    var optionsMap = new Map();
    utils.directoryMap.forEach((elem, index) =>
      optionsMap.set(elem.name, {
        id: index,
        label: elem.name,
        icon: (
          <Avatar label={elem.name} imgSrc={elem.imgsrc} variant="entity" />
        ),
        time: 99999999999,
        userId: elem.userId
      })
    );
    this.props.inboxMap.forEach(
      (elem, index) =>
        elem.peerUserIds.length === 1 &&
        optionsMap.set(elem.name, {
          id: elem.channel,
          label: elem.name,
          subTitle:
            "time" in elem &&
            elem.time !== undefined &&
            format(Date.now(), "MM/dd/yyyy") !== format(elem.time, "MM/dd/yyyy")
              ? format(elem.time, "MM/dd/yyyy")
              : format(elem.time, "hh:mm a"),
          icon:
            elem.peerUserIds.length === 1 ? (
              <Avatar
                label={elem.name}
                imgSrc={elem.imgsrc}
                variant="entity"
                className="slds-col"
              />
            ) : elem.imgsrc === "" ? (
              <GroupChatIcon
                style={{
                  width: "2rem",
                  height: "2rem",
                  backgroundColor: "#7e8be4",
                  borderRadius: "7px",
                  color: "white"
                }}
              />
            ) : (
              <Avatar
                label={elem.name}
                imgSrc={elem.imgsrc}
                variant="entity"
                className="slds-col"
              />
            ),
          time: elem.time,
          userId: elem.peerUserIds[0]
        })
    );
    console.log(optionsMap.values());

    this.setState({
      optionsMap: _.orderBy(
        Array.from(optionsMap.values()),
        ["time", "label"],
        ["desc", "asc"]
      )
    });
  }

  render() {
    console.log(this.state.optionsMap);
    return (
      <IconSettings iconPath="/assets/icons">
        <Modal
          isOpen={this.props.newChat}
          heading="Start Conversation"
          footer={[
            <Button
              label="Start"
              variant="brand"
              onClick={() => {
                /*console.log("this.state.selection");
                console.log(this.state.selection);
                console.log(this.state.selection.map(elem => elem.userId));*/
                this.props.createNewDirectGroup(
                  this.state.selection.map(elem => elem.userId)
                );
              }}
            />
          ]}
          onRequestClose={this.props.closeNewMessage}
          size="small"
        >
          <div style={{ height: "60vh", margin: "20px" }}>
            <Combobox
              id="combobox-base"
              disabled={this.props.disabled}
              isOpen={true}
              events={{
                onChange: (event, { value }) => {
                  if (this.props.action) {
                    this.props.action("onChange")(event, value);
                  } else if (console) {
                    console.log("onChange", event, value);
                  }
                  this.setState({ inputValue: value });
                },
                onRequestRemoveSelectedOption: (event, data) => {
                  this.setState({
                    inputValue: "",
                    selection: data.selection
                  });
                },
                onSelect: (event, data) => {
                  if (console) {
                    console.log("onSelect", event, data);
                  }
                  this.setState({
                    inputValue: "",
                    selection: data.selection
                  });
                }
              }}
              labels={{
                label: "With:",
                placeholder: "Search"
              }}
              menuItemVisibleLength={5}
              multiple
              options={comboboxFilterAndLimit({
                inputValue: this.state.inputValue,
                options: this.state.optionsMap,
                limit: 999,
                selection: this.state.selection
              })}
              selection={this.state.selection}
              variant="inline-listbox"
              value={this.state.inputValue}
            />
          </div>
        </Modal>
      </IconSettings>
    );
  }
}

export default NewMessage;
