import React from "react";
import marked from "marked";
//import * as customrender from "customrenderer.jsx";

export function getProfileURL(url) {
  console.log(url);
  if (url && url.endsWith("/profilephoto/005/F")) {
    return "";
  } else return url;
}
export function log(message) {
  console.log(message);
}
// highlights the search text term
export function getHighlightedText(text, higlight) {
  // Split text on higlight term, include term itself into parts, ignore case
  const parts = text.split(new RegExp(`(${higlight})`, "gi"));
  return (
    <span>
      {parts.map(part =>
        part.toLowerCase() === higlight.toLowerCase() ? <b>{part}</b> : part
      )}
    </span>
  );
}
const renderer = new marked.Renderer();
export function getMarkdownText(text) {
  renderer.link = function(href, title, text) {
    return (
      '<a target="_blank" href="' +
      href +
      '" title="' +
      title +
      '">' +
      text +
      "</a>"
    );
  };
  renderer.list = function(content, ordered, start) {
    const type = ordered
      ? 'ol class="slds-list_ordered"'
      : 'ul class="slds-list_dotted"';

    let output = `<${type} `;
    if (ordered && start !== undefined) {
      // eslint-disable-line no-undefined
      // The CSS that we use for lists hides the actual counter and uses ::before to simulate one so that we can
      // style it properly. We need to use a CSS counter to tell the ::before elements which numbers to show.
      output += ` style="counter-reset: list ${start - 1}"`;
    }
    output += `>\n${content}</${type}>`;
    console.log(output);
    return output;
  };
  renderer.listitem = function(text, bullet = "") {
    console.log(bullet);
    const taskListReg = /^\[([ |xX])] /;
    const isTaskList = taskListReg.exec(text);

    if (isTaskList) {
      return `<li class="list-item--task-list">${'<input type="checkbox" disabled="disabled" ' +
        (isTaskList[1] === " " ? "" : 'checked="checked" ') +
        "/> "}${text.replace(taskListReg, "")}</li>`;
    }

    // Added a span because if not whitespace nodes only
    // works in Firefox but not in Webkit
    return `<li><span>${text}</span></li>`;
  };
  var rawMarkup = marked(text, {
    renderer: renderer,
    sanitize: true
  }).trim();
  return { __html: rawMarkup };
}
export function BackIcon(props) {
  return (
    <svg
      style={props.style}
      width="35px"
      height="20px"
      class="svg-inline--fa fa-address-book fa-w-14"
      role="img"
      viewBox="0 0 576 512"
    >
      <path
        fill="currentColor"
        d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"
      ></path>
    </svg>
  );
}
export function GroupChatIcon(props) {
  return (
    <svg
      style={props.style}
      width="20px"
      height="20px"
      role="img"
      viewBox="0 0 640 512"
    >
      <g>
        <path
          fill="currentColor"
          d="M96 224a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64zm480 32h-64a63.81 63.81 0 0 0-45.1 18.6A146.27 146.27 0 0 1 542 384h66a32 32 0 0 0 32-32v-32a64.06 64.06 0 0 0-64-64zm-512 0a64.06 64.06 0 0 0-64 64v32a32 32 0 0 0 32 32h65.9a146.64 146.64 0 0 1 75.2-109.4A63.81 63.81 0 0 0 128 256zm480-32a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64z"
          opacity="0.4"
        ></path>
        <path
          fill="currentColor"
          d="M396.8 288h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 128 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 396.8 288zM320 256a112 112 0 1 0-112-112 111.94 111.94 0 0 0 112 112z"
        ></path>
      </g>
    </svg>
  );
}
export function UserChatIcon(props) {
  return (
    <svg
      style={props.style}
      width="20px"
      height="20px"
      class="svg-inline--fa fa-address-book fa-w-14"
      role="img"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
      ></path>
    </svg>
  );
}
export function SearchIcon(props) {
  return (
    <svg
      style={props.style}
      width="35px"
      height="20px"
      class="svg-inline--fa fa-address-book fa-w-14"
      role="img"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"
      ></path>
    </svg>
  );
}

export function getChannelName(props) {
  return (
    <svg
      style={props.style}
      width="35px"
      height="20px"
      class="svg-inline--fa fa-address-book fa-w-14"
      role="img"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z"
      ></path>
    </svg>
  );
}
