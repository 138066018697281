import React, { Component } from "react";
import FileInput from "./FileInput";
import { format } from "date-fns";
import Icon from "@salesforce/design-system-react/components/icon";
import Button from "@salesforce/design-system-react/components/button";
import Dropdown from "@salesforce/design-system-react/components/menu-dropdown";
import DropdownTrigger from "@salesforce/design-system-react/components/menu-dropdown/button-trigger";
import ChatEvent from "./ChatEvent";
import { getMarkdownText } from "./Utils/IntivaUtils.jsx";

export default class InBoundMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayActions: false,
      value: "",
      hover: false
    };
    this.toggleHover = this.toggleHover.bind(this);
  }
  toggleHover() {
    this.setState({ hover: !this.state.hover });
  }
  render() {
    return this.props.customType === "Event" ? (
      <ChatEvent
        messageId={this.props.messageId}
        message={"you " + this.props.message}
      />
    ) : (
      <li
        className="slds-chat-listitem slds-chat-listitem_outbound"
        style={{ cursor: "pointer", postion: "relative" }}
        onMouseLeave={() => {
          this.setState({ displayActions: false });
        }}
        onMouseEnter={() => this.setState({ displayActions: true })}
      >
        <div className="slds-chat-message">
          {this.state.displayActions ? (
            <Dropdown
              onSelect={item => {
                if (item.value === "A0") {
                  this.props.updateAsPriority();
                } else if (item.value === "B0") {
                  if (this.props.messageType === "file") {
                  }
                  this.props.initializeValue();
                } else if (item.value === "C0") {
                  this.props.deleteConfirmed();
                } else if (item.value === "D0") {
                  this.props.messageDetails();
                } else if (item.value === "E0") {
                  this.props.forwardMessage();
                }
              }}
              align="center"
              width="xx-small"
              options={
                this.props.messageType !== "file"
                  ? [
                      { label: "Priority", value: "A0" },
                      { label: "Resend", value: "B0" },
                      { label: "Recall", value: "C0" },
                      { label: "Details", value: "D0" },
                      { label: "Forward", value: "E0" }
                    ]
                  : [
                      { label: "Priority", value: "A0" },
                      { label: "Recall", value: "C0" },
                      { label: "Details", value: "D0" },
                      { label: "Forward", value: "E0" }
                    ]
              }
            >
              <DropdownTrigger>
                <Button
                  iconPath="symbols.svg#ellipsis"
                  variant="icon"
                  iconSize="large"
                  style={{
                    marginRight: "10px",

                    color: "rgb(10,132,255)",
                    postion: "absolute",
                    /* backgroundColor: this.state.hover
                    ? "rgba(0, 0, 0, .100)"
                    : "", */
                    backgroundColor: "rgba(0, 0, 0, .100)",
                    borderRadius: "99px"
                  }}
                  className="slds-align_absolute-center"
                  onMouseEnter={this.toggleHover}
                  onMouseLeave={this.toggleHover}
                />
              </DropdownTrigger>
            </Dropdown>
          ) : null}
          <div className="slds-chat-message__body">
            <div
              style={{
                backgroundColor: this.props.priority ? "" : "rgb(10,132,255)",
                postion: "relative"
              }}
              className={
                this.props.priority
                  ? "slds-chat-message__text slds-chat-message__text_delivery-failure"
                  : "slds-chat-message__text slds-chat-message__text_outbound"
              }
            >
              {this.props.messageType === "user" && (
                // <span>{this.props.message}</span>
                <div
                  dangerouslySetInnerHTML={getMarkdownText(this.props.message)}
                />
              )}

              {this.props.messageType === "file" && (
                <FileInput
                  fileName={this.props.fileName}
                  fileURL={this.props.fileURL}
                  fileMime={this.props.fileMime}
                />
              )}
              {this.props.priority ? (
                <div
                  class="slds-chat-message__text_delivery-failure-reason"
                  role="alert"
                >
                  <Icon size="x-small" path="symbols.svg#priorityOn" />
                  <span style={{ marginLeft: "10px" }}>Priority Message</span>
                </div>
              ) : null}
            </div>
            <div class="slds-chat-message__meta">
              {this.props.totalCount === 1 && this.props.unReadCount === 0 && (
                <span>Read •</span>
              )}

              {this.props.totalCount !== 1 &&
                //-1 is used bez current user is also included in the ReadReceipt count by sendBird
                this.props.unReadCount < this.props.totalCount && (
                  <span>
                    {this.props.totalCount - this.props.unReadCount}/
                    {this.props.totalCount} Read •
                  </span>
                )}
              {format(this.props.createdAt, "hh:mm a")}
              {this.props.messageType === "file" && (
                <a
                  href={this.props.fileURL}
                  style={{ color: "rgb(10,132,255)" }}
                >
                  <DownloadIcon></DownloadIcon>
                </a>
              )}
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export function DownloadIcon(props) {
  return (
    <svg
      style={props.style}
      width="20px"
      height="10px"
      role="img"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-143.6-28.9L288 302.6V120c0-13.3-10.7-24-24-24h-16c-13.3 0-24 10.7-24 24v182.6l-72.4-75.5c-9.3-9.7-24.8-9.9-34.3-.4l-10.9 11c-9.4 9.4-9.4 24.6 0 33.9L239 404.3c9.4 9.4 24.6 9.4 33.9 0l132.7-132.7c9.4-9.4 9.4-24.6 0-33.9l-10.9-11c-9.5-9.5-25-9.3-34.3.4z"
      ></path>
    </svg>
  );
}
