import React from "react";
import _ from "lodash";
import Modal from "@salesforce/design-system-react/components/modal";
import Divider from "@material-ui/core/Divider";
import Button from "@salesforce/design-system-react/components/button/";
import Avatar from "@salesforce/design-system-react/components/avatar";
import PillContainer from "@salesforce/design-system-react/components/pill-container";
import * as items from "./Items.jsx";
import * as SendBirdConfig from "./Config/SendBirdConfig";

class NewGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupName: this.props.groupName,
      groupDescription: this.props.groupDescription,
      members: this.props.members,
      options: this.props.options,
      filteredMap: this.props.directoryMap,
      file: null,
      value: ""
    };
    this.optionsfromSet = this.optionsfromSet.bind(this);
    this.filter = this.filter.bind(this);
    this.createGroup = this.createGroup.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  filter(event) {
    let searchValue = event.target.value;

    let newMap = new Map(this.props.directoryMap);
    function logMapElements(value, key, map) {
      if (!value.name.toLowerCase().includes(searchValue.toLowerCase()))
        newMap.delete(key);
    }
    // If the search bar isn't empty
    if (searchValue !== "") {
      // Assign the original list to currentList
      this.props.directoryMap.forEach(logMapElements);
    }
    console.log(newMap);
    this.setState({
      filteredMap: newMap,
      value: searchValue
    });
    // Set the filtered state based on what our rules added to newList
  }
  createGroup() {
    var params = new SendBirdConfig.sb.GroupChannelParams();
    params.isDistinct = false;
    params.customType = "group";
    params.addUserIds(Array.from(this.state.members));
    params.operatorUserIds = Array.from(this.state.members);
    params.name = this.state.groupName;
    var data = {
      groupDescription: this.state.groupDescription,
      pin: ""
    };
    params.data = JSON.stringify(data);
    this.state.file !== null
      ? (params.coverImage = this.state.file)
      : (params.coverUrl = ""); // https://sendbird-us-2.s3.amazonaws.com/CE8AB90F-8FD4-4EAA-9154-5D5C9E1FFB3E/channels/476b610aede2ae29e54d0344515ca745d837ef8d.jpg
    console.log("createGroup");
    console.log(params);
    var that = this;
    SendBirdConfig.sb.GroupChannel.createChannel(params, function(
      groupChannel,
      error
    ) {
      if (error) {
        console.log(error);
        return;
      }
      console.log(groupChannel);
      const params = new SendBirdConfig.sb.UserMessageParams();

      params.customType = "Event";
      params.message = "joined this group";
      groupChannel.sendUserMessage(params, function(message, error) {
        if (error) {
          console.log(error);
          return;
        }
        console.log("sendMessageToSB in createGroup");
        console.log(message);
      });
      that.state.members.forEach(member => {
        let item = that.props.directoryMap.get(member);
        params.message = "added " + item.name + " to this group";
        groupChannel.sendUserMessage(params, function(message, error) {
          if (error) {
            console.log(error);
            return;
          }
          console.log("sendMessageToSB");
          console.log(message);
        });
      });
      that.props.openNewGroup(groupChannel);
    });
  }
  onRequestRemovePill = (event, data) => {
    let mems = this.state.members;
    console.log(mems.delete(data.option.id));
    this.setState({
      members: mems,
      options: this.optionsfromSet(mems)
    });
  };
  handleChange(event) {
    if (event.target.files !== undefined) {
      this.setState({ file: event.target.files[0] });
    }
  }

  optionsfromSet(members) {
    let options = [];

    members.forEach(member => {
      let item = this.props.directoryMap.get(member);
      options.push({
        avatar: (
          <Avatar imgSrc={item.imgsrc} label={item.name} inverse={true} />
        ),
        id: item.userId,
        label: item.name,
        title: item.name
      });
    });
    return options;
  }
  render() {
    console.log("this.props.directoryMap");
    console.log(this.props.directoryMap);
    return (
      <Modal
        isOpen={this.props.newGroup}
        heading="Create a group"
        footer={[
          <Button label="Cancel" onClick={this.props.close} />,
          this.state.groupName === "" || this.state.members.size < 2 ? (
            <Button label="Save" disabled variant="brand" />
          ) : (
            <Button label="Save" variant="brand" onClick={this.createGroup} />
          )
        ]}
        onRequestClose={this.props.close}
        size="small"
      >
        <section className="slds-p-around_large">
          <div className="slds-form-element slds-m-bottom_large">
            <label className="slds-form-element__label">
              <abbr className="slds-required" title="required">
                *
              </abbr>
              Group Name
            </label>
            <div className="slds-form-element__control">
              <input
                id="opptyName"
                value={this.state.groupName}
                className="slds-input"
                type="text"
                placeholder="Enter name"
                onChange={event => {
                  this.setState({ groupName: event.target.value });
                }}
              />
            </div>
          </div>
          <div className="slds-form-element slds-m-bottom_large">
            <label className="slds-form-element__label">Group Cover</label>
            <div className="slds-form-element__control">
              <input
                type="file"
                accept="image/gif,image/jpeg,image/jpg,image/png"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="slds-form-element slds-m-bottom_large">
            <label className="slds-form-element__label">
              Group Description (optional)
            </label>
            <div className="slds-form-element__control">
              <textarea
                value={this.state.groupDescription}
                id="description"
                className="slds-textarea"
                placeholder="Enter description"
                onChange={event => {
                  this.setState({ groupDescription: event.target.value });
                }}
              />
            </div>
          </div>
          <div className="slds-form-element slds-m-bottom_large">
            <label className="slds-form-element__label">
              <abbr className="slds-required" title="required">
                *
              </abbr>
              Add Members
            </label>
            <div className="slds-grid slds-grid_vertical-align-start">
              <PillContainer
                id="pill-container-with-avatars"
                options={this.state.options}
                onRequestRemovePill={this.onRequestRemovePill}
              />
            </div>

            <div>
              <div
                style={{
                  height: "40px",
                  backgroundColor: "white",
                  border: "solid transparent",
                  borderRadius: "20px"
                }}
                className="slds-grid slds-grid_align-spread"
              >
                <input
                  className="slds-col"
                  value={this.state.value}
                  style={{
                    backgroundColor: "transparent",
                    paddingRight: "0px",
                    border: "none",
                    outline: 0
                  }}
                  placeholder="search"
                  onChange={this.filter}
                ></input>
              </div>
            </div>
            <Divider />
            <ul
              style={{
                height: "180px",
                overflow: "scroll",
                overflowScrolling: "touch",
                WebkitOverflowScrolling: "touch"
              }}
            >
              {_.orderBy(
                Array.from(this.state.filteredMap.values()),
                ["name"],
                ["asc"]
              ).map((item, index) => (
                <items.DirectoryItem
                  key={index}
                  item={item}
                  onClick={() => {
                    let mems = this.state.members;
                    console.log(mems.add(item.userId));
                    this.setState({
                      members: mems,
                      options: this.optionsfromSet(mems)
                    });
                  }}
                />
              ))}
            </ul>
          </div>
        </section>
      </Modal>
    );
  }
}
export function BackIcon(props) {
  return (
    <svg
      style={props.style}
      width="35px"
      height="20px"
      class="svg-inline--fa fa-address-book fa-w-14"
      role="img"
      viewBox="0 0 576 512"
    >
      <path
        fill="currentColor"
        d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"
      ></path>
    </svg>
  );
}
export default NewGroup;
