import React, { Component } from "react";
import { format } from "date-fns";
import * as SendBirdConfig from "./Config/SendBirdConfig";
import Button from "@salesforce/design-system-react/components/button";
import {
  getProfileURL,
  UserChatIcon,
  getMarkdownText
} from "./Utils/IntivaUtils.jsx";
import Avatar from "@salesforce/design-system-react/components/avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Popover from "@salesforce/design-system-react/components/popover";
import Tooltip from "@salesforce/design-system-react/components/tooltip";
import Card from "@salesforce/design-system-react/components/card";
import { Scrollbars } from "react-custom-scrollbars";
import Modal from "@salesforce/design-system-react/components/modal";
import ProgressBar from "@salesforce/design-system-react/components/progress-bar";
import Spinner from "@salesforce/design-system-react/components/spinner";
import FileInput from "./FileInput";
import Icon from "@salesforce/design-system-react/components/icon";
import InBoundMessage from "./InBoundMessage";
import Slide from "@material-ui/core/Slide";
import RightPanel from "./RightPanel";
import PrintMode from "./PrintMode";
import ChatEvent from "./ChatEvent";
import MessageDetails from "./MessageDetails";
import ForwardMessage from "./ForwardMessage";
import EmojiBody from "./EmojiBody";
import * as utils from "./Utils/utils.ts";

export default class MessageBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listMessages: [],
      // does not include current logged user
      peerUserIds: [],
      logedUser: this.props.loggedUser,
      isLoading: true,
      value: "",
      groupChannel: {},
      connectionstatus: "",
      isPriporityActive: false,
      typingUsersMap: new Map(),
      isPeerUserTyping: false,
      peerUserLastseen: 0,
      doScroll: false,
      width: 0,
      height: 0,
      isGroup: false,
      fileUplading: false,
      loaded: 100,
      displayRightPanel: false,
      printMode: false,
      updateMessageID: -1,
      messageDetails: { messageDetailsId: -1, messageDetailsType: "" },
      forwardMessage: {
        forwardMessageId: -1,
        forwardMessageType: "",
        forwardMessageValue: "",
        file: {}
      },
      name: "",
      imgSrc: "",
      uniqueChannelHandler: ""
    };
    this.fileInput = React.createRef();
    this.imageFile = React.createRef();
    this.messageInput = React.createRef();
    this.sendMessageToSB = this.sendMessageToSB.bind(this);
    this.renderConversation = this.renderConversation.bind(this);
    this.addChannelHandler = this.addChannelHandler.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.fileUploadProgress = this.fileUploadProgress.bind(this);
    this.sendFileToSB = this.sendFileToSB.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.displayEdit = this.displayEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.MessageUpdated = this.MessageUpdated.bind(this);
    this.MessageReceived = this.MessageReceived.bind(this);
    this.MessageDeleted = this.MessageDeleted.bind(this);
    this.deleteMessage = this.deleteMessage.bind(this);
    this.TypingStatus = this.TypingStatus.bind(this);
    this.ReadReceiptUpdated = this.ReadReceiptUpdated.bind(this);
    this.clearConnversation = this.clearConnversation.bind(this);
    this.closeRightPanel = this.closeRightPanel.bind(this);
    this.ChannelChanged = this.ChannelChanged.bind(this);
    this.UserReceivedInvitation = this.UserReceivedInvitation.bind(this);
    this.UserJoined = this.UserJoined.bind(this);
    this.sendEventMessageToSB = this.sendEventMessageToSB.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.pinConversation = this.pinConversation.bind(this);
    this.emojiClick = this.emojiClick.bind(this);
    this.componentCleanup = this.componentCleanup.bind(this);
  }
  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight - 120 - 104
    });
    !this.props.printMode && this.scrollComponent.scrollToBottom();
  }
  componentCleanup() {
    // this will hold the cleanup code
    console.log("componentCleanup");
    console.log(SendBirdConfig.sb);
    console.log("uniqueChannelHandler");
    console.log(this.state.uniqueChannelHandler);
    this.state.groupChannel.endTyping();
    SendBirdConfig.sb.removeChannelHandler(this.state.uniqueChannelHandler);
  }
  addChannelHandler() {
    let channelHandler = new SendBirdConfig.sb.ChannelHandler();
    channelHandler.onMessageReceived = this.MessageReceived;
    channelHandler.onMessageUpdated = this.MessageUpdated;
    channelHandler.onMessageDeleted = this.MessageDeleted;
    channelHandler.onTypingStatusUpdated = this.TypingStatus;
    channelHandler.onReadReceiptUpdated = this.ReadReceiptUpdated;
    channelHandler.onChannelChanged = this.ChannelChanged;
    channelHandler.onUserReceivedInvitation = this.UserReceivedInvitation;
    channelHandler.onUserJoined = this.userJoined;
    this.setState({
      uniqueChannelHandler:
        this.state.groupChannel.url + this.state.logedUser.userId + utils.UUID
    });
    console.log("uniqueChannelHandler");
    console.log(this.state.uniqueChannelHandler);
    SendBirdConfig.sb.addChannelHandler(
      this.state.uniqueChannelHandler,
      channelHandler
    );
  }
  componentWillUnmount() {
    this.componentCleanup();
    window.removeEventListener("beforeunload", this.componentCleanup);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("beforeunload", this.componentCleanup);
    this.getGroupChannel();
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.channel !== prevProps.channel) {
      this.componentCleanup();
      this.getGroupChannel();
      // the above part is only for testing need to be removed
    }
  }

  MessageReceived(channel, message) {
    console.log("MessageReceived in Messageboard.jsx");
    console.log(this.state.groupChannel.url);
    let index = this.state.listMessages.findIndex(m => {
      return m.messageId.toString() === message.messageId.toString();
    });
    if (channel.url === this.state.groupChannel.url && index === -1) {
      console.log("MessageReceived");
      console.log(message);
      channel.markAsRead();

      var data = {
        priority: "false"
      };
      channel.updateMetaData(data, true, function(response, error) {
        if (error) {
          return;
        }
      });
      let newMessage = {
        messageType: message.messageType,
        message: message.message,
        fileURL: message.url,
        fileMime: message.type,
        fileName: message.name,
        userId: message._sender ? message._sender.userId : null,
        nickName: message._sender ? message._sender.nickname : null,
        createdAt: message.createdAt,
        messageId: message.messageId,
        displayedits: false,
        priority: message.customType === "priority" ? true : false,
        customType: message.customType
      };
      console.log("newMessage");
      console.log(newMessage);
      this.setState(
        {
          listMessages: [...this.state.listMessages, newMessage]
        },
        () => {
          this.scrollComponent.scrollToBottom();
        }
      );
    }
  }

  MessageUpdated(channel, updatedMessage) {
    if (channel.url !== this.state.groupChannel.url) {
      return;
    }
    console.log(" in side the updatedMessage");
    let index = this.state.listMessages.findIndex(message => {
      return message.messageId === updatedMessage.messageId;
    });
    if (index !== -1) {
      let newlistMessages = this.state.listMessages;
      newlistMessages[index].message = updatedMessage.message;
      newlistMessages[index].priority =
        updatedMessage.customType === "priority" ? true : false;
      this.setState({ listMessages: newlistMessages });
    }
    //console.log(index);
  }

  MessageDeleted(channel, deletedMessageID) {
    if (channel.url !== this.state.groupChannel.url) {
      return;
    }
    console.log("deletedMessageID");
    let index = this.state.listMessages.findIndex(message => {
      return message.messageId.toString() === deletedMessageID.toString();
    });
    //  console.log(index);
    if (index !== -1) {
      let newlistMessages = this.state.listMessages;
      newlistMessages.splice(index, 1);
      this.setState({ listMessages: newlistMessages });
    }
    // console.log(index);
  }
  TypingStatus(groupChannel) {
    if (groupChannel.url !== this.state.groupChannel.url) {
      return;
    }
    //console.log("TypingStatus");
    var members = groupChannel.getTypingMembers();
    //console.log(this.scrollComponent.getValues());
    var updateTypingUsersMap = new Map();
    members.forEach(m =>
      updateTypingUsersMap.set(m.userId, this.props.directoryMap.get(m.userId))
    );
    this.setState(
      {
        isPeerUserTyping: true,
        typingUsersMap: updateTypingUsersMap
      },
      () => {
        this.scrollComponent.scrollToBottom();
      }
    );

    // Refresh typing status of members within channel.
  }

  ReadReceiptUpdated(groupChannel) {
    //console.log("onReadReceiptUpdated");
    if (this.state.groupChannel.url === groupChannel.url) {
      console.log("onReadReceiptUpdated");
      if (this.state.isGroup) {
        this.setState({
          listMessages: this.state.listMessages.map(e => ({
            ...e,
            unReadCount: groupChannel.getReadReceipt(groupChannel.lastMessage)
          }))
        });
      } else {
        this.setState({
          listMessages: this.state.listMessages.map(e => ({
            ...e,
            unReadCount: 0
          }))
        });
      }
    }
  }

  getGroupChannel() {
    console.log("getGroupChannel");
    var that = this;
    SendBirdConfig.sb.GroupChannel.getChannel(this.props.channel, function(
      groupChannel,
      error
    ) {
      if (error) {
        return;
      }
      console.log(groupChannel);
      console.log("this.state.groupChannel.data");
      console.log(groupChannel.data);
      var isGroup = !groupChannel.isDistinct;
      //   console.log(JSON.parse(groupChannel.data));
      groupChannel.markAsRead();
      var prevMessageListQuery = groupChannel.createPreviousMessageListQuery();
      prevMessageListQuery.limit = "100";
      prevMessageListQuery.reverse = false;
      // Retrieving previous messages.
      prevMessageListQuery.load(function(messages, error) {
        if (error) {
          return;
        }
        var retrivedMessages = messages.map(message => {
          return {
            messageType: message.messageType,
            message: message.message,
            fileURL: message.url,
            fileName: message.name,
            fileMime: message.type,
            userId: message._sender ? message._sender.userId : null,
            nickName: message._sender ? message._sender.nickname : null,
            createdAt: message.createdAt,
            messageId: message.messageId,
            displayedits: false,
            priority: message.customType === "priority",
            customType: message.customType,
            unReadCount: groupChannel.getReadReceipt(message)
          };
        });
        // console.log(retrivedMessages);
        console.log("retrivedMessages");
        //console.log(messages);

        var peerUserIds = Object.keys(groupChannel.memberMap);
        peerUserIds = peerUserIds.filter(
          peerUserId => peerUserId !== that.state.logedUser.userId
        );

        var name = utils.getChannelName(
          isGroup,
          groupChannel.name,
          peerUserIds,
          that.state.logedUser.userId
        );
        console.log("getChannelName in MessageBoard");
        console.log(name);
        that.setState(
          {
            groupChannel: groupChannel,
            isGroup: isGroup,
            listMessages: retrivedMessages,
            name: name,
            peerUserIds: peerUserIds,
            imgSrc: groupChannel.coverUrl,
            value: ""
          },
          () => {
            that.addChannelHandler();
            that.scrollComponent.scrollToBottom();
            that.messageInput.current.focus();
          }
        );
      }); // Retrieving previous messages. load() end
    }); // end of getChannel

    console.log(this.state);
  }

  /*   static getDerivedStateFromProps(nextProps, prevState) {
    console.log("in getDerivedStateFromProps");
    if (nextProps.channel !== prevState.groupChannel.url) {
      return { groupChannel: nextProps.channel, isLoading: true };
    } else return null;
  } */
  deleteMessage(index) {
    console.log("In msg Deleted" + this.state.listMessages[index]);
    // For a text message
    const params = new SendBirdConfig.sb.UserMessageParams();
    const that = this;
    // The MESSAGE_ID below indicates the unique message ID of a UserMessage object to update.
    this.state.groupChannel.updateUserMessage(
      this.state.listMessages[index].messageId,
      params,
      function(message, error) {
        if (error) {
          console.log("In msg Deleted" + error);
          return;
        }

        // When a message has been successfully sent to a channel, create items with keys.
        that.state.groupChannel.deleteMessage(message, function(
          message,
          error
        ) {
          if (error) {
            return;
          }
        });
      }
    );
  }
  updateMessage(index) {
    console.log("In msg update" + this.state.listMessages[index]);
    // For a text message
    const params = new SendBirdConfig.sb.UserMessageParams();
    params.message = this.state.value;
    params.translationTargetLanguages = ["es"];
    const that = this;
    // The MESSAGE_ID below indicates the unique message ID of a UserMessage object to update.
    this.state.groupChannel.updateUserMessage(
      this.state.listMessages[index].messageId,
      params,
      function(message, error) {
        if (error) {
          console.log("In msg Updated" + error);
          return;
        }
        let updatedMessages = that.state.listMessages;
        updatedMessages[index].message = message.message;
        updatedMessages[index].priority =
          message.customType === "priority" ? true : false;
        that.setState({
          listMessages: updatedMessages,
          updateMessageID: -1,
          value: ""
        });
      }
    );
  }
  updateMessageAsPriority(index) {
    console.log("In  updateMessageAsPriority" + this.state.listMessages[index]);
    // For a text message
    if (this.state.listMessages[index].messageType === "user") {
      const params = new SendBirdConfig.sb.UserMessageParams();
      params.customType = this.state.listMessages[index].priority
        ? ""
        : "priority";
      const that = this;
      // The MESSAGE_ID below indicates the unique message ID of a UserMessage object to update.
      this.state.groupChannel.updateUserMessage(
        this.state.listMessages[index].messageId,
        params,
        function(message, error) {
          if (error) {
            console.log("In msg Deleted" + error);
            return;
          }
          let updatedMessages = that.state.listMessages;
          updatedMessages[index].priority = !(message.customType === "");
          that.setState({
            listMessages: updatedMessages,
            value: ""
          });
        }
      );
    } else {
      const params = new SendBirdConfig.sb.FileMessageParams();
      params.customType = this.state.listMessages[index].priority
        ? ""
        : "priority";
      const that = this;
      // The MESSAGE_ID below indicates the unique message ID of a UserMessage object to update.
      this.state.groupChannel.updateFileMessage(
        this.state.listMessages[index].messageId,
        params,
        function(message, error) {
          if (error) {
            console.log("In msg Deleted" + error);
            return;
          }
          let updatedMessages = that.state.listMessages;
          updatedMessages[index].priority = !(message.customType === "");
          that.setState({
            listMessages: updatedMessages,
            value: ""
          });
        }
      );
    }
    var data = {
      priority: "true"
    };

    this.state.groupChannel.updateMetaData(data, true, function(
      response,
      error
    ) {
      if (error) {
        return;
      }
    });
  }
  fileUploadProgress(event) {
    // Check progress of file upload request.
    var progress = Math.floor((event.loaded / event.total) * 100);
    this.setState({
      fileUplading: true,
      loaded: progress
    });
    console.log(parseInt(Math.floor((event.loaded / event.total) * 100)) + "%");
    // TODO: Display uplad progress with UI component.
  }
  sendFileToSB(event) {
    // Sending a file message with a raw file

    console.log("sendFileToSB");

    const params = new SendBirdConfig.sb.FileMessageParams();
    var fileData = this.fileInput;
    if (event.target.id === "imageFile") fileData = this.imageFile;
    if (fileData.current.files.length === 0) {
      return;
    }
    console.log(event.target);
    params.file = fileData.current.files[0]; // Or .fileUrl  = FILE_URL (You can also send a file message with a file URL.)
    // if(this.fileInput.current.files[0])
    params.fileName = fileData.current.files[0].name;
    params.fileSize = fileData.current.files[0].size;
    params.mimeType = fileData.current.files[0].type;

    const that = this;
    this.state.groupChannel.sendFileMessage(
      params,
      this.fileUploadProgress,
      function(fileMessage, error) {
        if (error) {
          console.log(error);
          return;
        }
        console.log(fileMessage);
        let newMessage = {
          messageType: fileMessage.messageType,
          message: fileMessage.message,
          fileURL: fileMessage.url,
          fileName: fileMessage.name,
          fileMime: fileMessage.type,
          userId: fileMessage._sender ? fileMessage._sender.userId : null,
          nickName: fileMessage._sender ? fileMessage._sender.nickname : null,
          createdAt: fileMessage.createdAt,
          messageId: fileMessage.messageId,
          displayedits: false,
          priority: fileMessage.customType === "priority" ? true : false
        };
        that.setState(
          {
            listMessages: [...that.state.listMessages, newMessage],
            value: "",

            fileUplading: false
          },
          () => {
            that.scrollComponent.scrollToBottom();
          }
        );
      }
    );
    console.log(
      fileData.current.files[0].name +
        " " +
        fileData.current.files[0].size +
        " " +
        fileData.current.files[0].type
    );
  }
  sendMessageToSB() {
    if (this.state.updateMessageID === -1) {
      console.log("results " + this.state.groupChannel.members);
      const params = new SendBirdConfig.sb.UserMessageParams();
      const that = this;
      /*   const emojis = require("emojis-list");
    console.log(this.state.value + emojis[0]); */
      params.message = this.state.value.trim();
      if (this.state.isPriporityActive) params.customType = "priority"; //metaData.priority = this.state.isPriporityActive;

      params.translationTargetLanguages = ["es", "ko"];
      this.state.groupChannel.sendUserMessage(params, function(message, error) {
        if (error) {
          console.log(error);
          return;
        }
        console.log("sendMessageToSB");
        console.log(message);
        let newMessage = {
          messageType: message.messageType,
          fileURL: message.url,
          fileName: message.name,
          fileMime: message.type,
          message: message.message,
          userId: message._sender ? message._sender.userId : null,
          nickName: message._sender ? message._sender.nickname : null,
          createdAt: message.createdAt,
          messageId: message.messageId,
          displayedits: false,
          priority: message.customType === "priority" ? true : false,
          unReadCount: that.state.groupChannel.getReadReceipt(message)
        };
        that.setState(
          {
            listMessages: [...that.state.listMessages, newMessage],
            value: "",
            isPriporityActive: false
          },
          () => {
            that.scrollComponent.scrollToBottom();
          }
        );
      });
    } else {
      this.updateMessage(this.state.updateMessageID);
    }
    this.messageInput.current.focus();
  }

  sendEventMessageToSB(customType, value) {
    console.log("results " + this.state.groupChannel.members);
    const params = new SendBirdConfig.sb.UserMessageParams();
    const that = this;

    params.message = value;
    params.customType = customType;

    this.state.groupChannel.sendUserMessage(params, function(message, error) {
      if (error) {
        console.log(error);
        return;
      }
      console.log("sendMessageToSB");
      console.log(message);
      let newMessage = {
        messageType: message.messageType,
        fileURL: message.url,
        fileName: message.name,
        fileMime: message.type,
        message: message.message,
        userId: message._sender ? message._sender.userId : null,
        nickName: message._sender ? message._sender.nickname : null,
        createdAt: message.createdAt,
        messageId: message.messageId,
        displayedits: false,
        priority: message.customType === "priority" ? true : false,
        customType: message.customType
      };
      that.setState(
        {
          listMessages: [...that.state.listMessages, newMessage]
        },
        () => {
          that.scrollComponent.scrollToBottom();
        }
      );
    });
  }

  ChannelChanged(channel) {
    console.log("onChannelChanged in MessageBoard.js");
    console.log(channel);
    if (
      channel.url === this.state.groupChannel.url &&
      channel.customType === "group" &&
      (channel.name !== this.state.name ||
        channel.coverUrl !== this.state.imgSrc ||
        this.state.peerUserIds !== Object.keys(channel.memberMap))
    ) {
      this.setState({
        name: channel.name,
        imgSrc: channel.coverUrl
        // groupChannel: channel
      });
    }
  }
  UserReceivedInvitation(channel) {
    console.log("UserReceivedInvitation in MessageBoard.js");
    console.log(this.state.peerUserIds);
    console.log(Object.keys(channel.memberMap));
    this.setState({ peerUserIds: Object.keys(channel.memberMap) });
  }
  UserJoined(channel) {
    console.log("UserJoined in MessageBoard.js");
    console.log(channel);
  }
  handleChange(event) {
    this.setState({ value: event.target.value });

    if (this.state.groupChannel !== undefined && event.target.value.length > 0)
      this.state.groupChannel.startTyping();

    if (
      this.state.groupChannel !== undefined &&
      event.target.value.length === 0
    )
      this.state.groupChannel.endTyping();
  }
  handleEnter(event) {
    //to skip shift + Enter use (&& event.shiftKey === false), we also need to handle new line with this
    if (event.key === "Enter" && event.shiftKey === false)
      this.sendMessageToSB();
  }

  render() {
    return (
      <React.Fragment>
        {!this.props.printMode && (
          <Card header={this.renderHeader()} footer={this.renderBottom()}>
            <div className="slds-grid">
              <Scrollbars
                className="slds-border_top"
                ref={c => {
                  this.scrollComponent = c;
                }}
                style={{
                  height: this.state.height
                }}
              >
                {this.renderConversation()}
              </Scrollbars>
              <Slide
                direction="left"
                in={this.state.displayRightPanel}
                timeout={{ enter: 300, exit: 300 }}
                mountOnEnter
                unmountOnExit
              >
                <RightPanel
                  isGroup={this.state.isGroup}
                  name={this.state.name}
                  height={this.state.height}
                  imgsrc={this.state.imgSrc}
                  directoryMap={this.props.directoryMap}
                  peerUserIds={this.state.peerUserIds}
                  close={this.closeRightPanel}
                  clearConnversation={this.clearConnversation}
                  setPrintMode={this.props.setPrintMode}
                  renderHeader={this.renderHeader}
                  groupChannel={this.state.groupChannel}
                  sendEventToSB={this.sendEventMessageToSB}
                  pinConversation={this.pinConversation}
                  pin={
                    this.state.groupChannel.data !== undefined &&
                    this.state.groupChannel.data !== "" &&
                    JSON.parse(this.state.groupChannel.data).pin === "true"
                  }
                />
              </Slide>
            </div>
            <Modal
              isOpen={this.state.fileUplading}
              heading="Please Wait ..."
              cursor="progress"
              size="small"
            >
              {this.uploadFile()}
            </Modal>
            {this.state.messageDetails.messageDetailsId !== -1 && (
              <Modal
                isOpen={!(this.state.messageDetails.messageDetailsId === -1)}
                onRequestClose={() => {
                  var messageDetails = {
                    messageDetailsId: -1,
                    messageDetailsType: ""
                  };
                  this.setState({ messageDetails: messageDetails });
                }}
                heading="Message Details"
                size="medium"
              >
                <MessageDetails
                  messageDetailsId={this.state.messageDetails.messageDetailsId}
                  messageDetailsType={
                    this.state.messageDetails.messageDetailsType
                  }
                  groupChannel={this.state.groupChannel}
                  totalCount={this.state.peerUserIds.length}
                />
              </Modal>
            )}
            {this.state.forwardMessage.forwardMessageId !== -1 && (
              <ForwardMessage
                members={new Set()}
                forwardMessageId={this.state.forwardMessage.forwardMessageId}
                forwardMessageType={
                  this.state.forwardMessage.forwardMessageType
                }
                file={this.state.forwardMessage.file}
                forwardMessageValue={
                  this.state.forwardMessage.forwardMessageValue
                }
                close={() => {
                  var forwardMessage = {
                    forwardMessageId: -1,
                    forwardMessageType: "",
                    forwardMessageValue: "",
                    file: {}
                  };
                  this.setState({ forwardMessage: forwardMessage });
                }}
                directoryMap={this.props.directoryMap}
              />
            )}
          </Card>
        )}

        {this.props.printMode && (
          <PrintMode
            setPrintMode={this.props.setPrintMode}
            listMessages={this.state.listMessages}
            imageURL={this.state.imgSrc}
            Name={this.state.name}
            directoryMap={this.props.directoryMap}
            peerUser={this.state.peerUserIds}
          ></PrintMode>
        )}
      </React.Fragment>
    );
  }

  renderHeader() {
    return (
      <div style={{ paddingBottom: "5px" }}>
        <div
          className="slds-grid slds-gutters"
          onClick={this.closeRightPanel}
          style={{ cursor: "pointer" }}
        >
          <div
            style={{
              paddingLeft: "10px",
              marginRight: "10px",
              position: "relative"
            }}
          >
            {// if this is NOT a Group
            !this.state.isGroup &&
              (this.state.peerUserIds.length === 1 ? (
                <Avatar
                  size="large"
                  variant="entity"
                  label={this.state.name}
                  imgSrc={getProfileURL(
                    this.props.directoryMap.get(this.state.peerUserIds[0])
                      .imgsrc
                  )}
                />
              ) : (
                <UserChatIcon
                  style={{
                    width: "3rem",
                    height: "3rem",
                    backgroundColor: "#7e8be4",
                    borderRadius: "10px",
                    color: "white",
                    paddingTop: "10px",
                    paddingBottom: "10px"
                  }}
                />
              ))}
            {// if this is a Group
            this.state.isGroup &&
              (this.state.imgSrc === "" ? (
                <GroupChatIcon
                  style={{
                    width: "3rem",
                    height: "3rem",
                    backgroundColor: "#7e8be4",
                    borderRadius: "10px",
                    color: "white",
                    paddingTop: "10px",
                    paddingBottom: "10px"
                  }}
                />
              ) : (
                <Avatar
                  label={this.state.name}
                  imgSrc={this.state.imgSrc}
                  variant="entity"
                  size="large"
                />
              ))}
          </div>
          <Tooltip
            id="tooltip"
            align="top left"
            content={this.state.name}
            variant="list-item"
          >
            <div
              style={{
                left: "0px",
                postion: "absolute",
                paddingTop: "15px",
                width: "600px",
                "white-space": "nowrap",
                overflow: "hidden",
                "text-overflow": "ellipsis"
              }}
              className="slds-text-heading_small"
            >
              <b>{this.state.name}</b>
            </div>
          </Tooltip>
        </div>
        <div
          style={{
            position: "absolute",
            top: "25px",
            right: "16px"
          }}
        >
          <Button
            iconPath={
              this.state.groupChannel.data !== undefined &&
              this.state.groupChannel.data !== "" &&
              JSON.parse(this.state.groupChannel.data).pin === "true"
                ? "symbols.svg#pinned"
                : "symbols.svg#pin"
            }
            iconSize="small"
            iconVariant="bare"
            onClick={this.pinConversation}
            variant="icon"
          />
          <Button
            iconPath="symbols.svg#settings"
            iconSize="small"
            iconVariant="bare"
            onClick={this.closeRightPanel}
            variant="icon"
          />
        </div>
      </div>
    );
  }
  pinConversation() {
    var params = new SendBirdConfig.sb.GroupChannelParams();
    var data = {
      groupDescription: JSON.parse(this.state.groupChannel.data)
        .groupDescription,
      pin: JSON.parse(this.state.groupChannel.data).pin === "true" ? "" : "true"
    };
    params.data = JSON.stringify(data);
    console.log("pinConversation");
    console.log(params.data);
    this.state.groupChannel.updateChannel(params, function(
      groupChannel,
      error
    ) {
      if (error) {
        console.log(error);
        return;
      }
      console.log("updateGroup Data");
      console.log(groupChannel);
    });
  }
  clearConnversation() {
    var that = this;
    this.state.groupChannel.resetMyHistory(function(response, error) {
      if (error) {
        return;
      }
      console.log(response);
      that.getGroupChannel();
    });
  }
  closeRightPanel() {
    this.setState({
      displayRightPanel: !this.state.displayRightPanel
    });
  }
  renderBottom() {
    return (
      <div style={{ paddingLeft: "40px", paddingRight: "40px" }}>
        <div className="slds-grid">
          <div className="slds-button-group">
            <div>
              <Button
                className="slds-button slds-button_icon   slds-button_icon-medium slds-button--icon-border-filled "
                iconPath="symbols.svg#attach"
                variant="icon"
                onClick={() => {
                  this.fileInput.current.value = null;
                  this.fileInput.current.click();
                }}
              ></Button>
              <input
                style={{ display: "none" }}
                type="file"
                accept="*"
                title="Add files"
                ref={this.fileInput}
                onChange={this.sendFileToSB}
                autoFocus={true}
              />
            </div>
            <Button
              className="slds-button slds-button_icon   slds-button_icon-medium slds-button--icon-border-filled "
              iconPath="symbols.svg#image"
              iconName="image"
              variant="icon"
              onClick={() => {
                this.imageFile.current.click();
              }}
            ></Button>
            <input
              id="imageFile"
              style={{ display: "none" }}
              type="file"
              title="Add files"
              ref={this.imageFile}
              onChange={this.sendFileToSB}
              accept="image/png, image/jpeg"
            />
            <Tooltip align="top left" content="Send Message as a Priority">
              <Button
                style={{
                  color: this.state.isPriporityActive ? "#c23934" : ""
                }}
                className="slds-button slds-button_icon   slds-button_icon-medium slds-button--icon-border-filled "
                iconPath="symbols.svg#priorityOn"
                variant="icon"
                colorVariant="light"
                onClick={() =>
                  this.setState({
                    isPriporityActive: !this.state.isPriporityActive
                  })
                }
              />
            </Tooltip>
            <Popover
              style={{ width: "225px" }}
              body={<EmojiBody onEmojiClick={this.emojiClick} />}
              align="top left"
            >
              <Button
                className="slds-button slds-button_icon   slds-button_icon-medium slds-button--icon-border-filled "
                iconPath="symbols.svg#emoji"
                variant="icon"
                colorVariant="light"
              ></Button>
            </Popover>
          </div>
          <div className="slds-col_padded">
            <textarea
              style={{
                backgroundColor: "#fff",
                border: "1px solid #dddbda",
                borderRadius: "0.25rem",
                width: "100%",
                lineHeight: "1.0rem"
              }}
              ref={this.messageInput}
              placeholder="Type a message..."
              value={this.state.value}
              onKeyPress={this.handleEnter}
              onChange={this.handleChange}
              autoFocus
            />
          </div>
          <Button
            iconPath="symbols.svg#send"
            iconPosition="right"
            label="Send"
            size="small"
            variant="base"
            onClick={this.sendMessageToSB}
          />
        </div>
      </div>
    );
  }

  emojiClick(index) {
    this.setState({ value: this.state.value + emojis[index] });
  }

  uploadFile() {
    return (
      <section className="slds-p-around_medium">
        {this.state.loaded !== 100 ? (
          <div>
            <span>Upload Status {this.state.loaded}% </span>
            <ProgressBar
              id="progress-bar-success"
              value={this.state.loaded}
              color="success"
            />
          </div>
        ) : (
          <div>
            <div style={{ position: "relative", height: "5rem" }}>
              <Spinner size="large" variant="brand" />
            </div>
          </div>
        )}
      </section>
    );
  }

  displayEdit(index) {
    // console.log("on enter" + index);
    let items = this.state.listMessages;
    items[index].displayedits = true;
    this.setState({ listMessages: items });
  }

  renderConversation() {
    var consecutive = true;
    return (
      <ul className="slds-chat-list">
        {this.state.listMessages.map((m, index) => {
          return m.userId !== this.state.logedUser.userId
            ? [
                index === 0 ? (
                  <ChatDateEvent key={m.createdAt} createdDate={m.createdAt} />
                ) : (
                  format(m.createdAt, "MM/dd/yyyy") !==
                    format(
                      this.state.listMessages[index - 1].createdAt,
                      "MM/dd/yyyy"
                    ) && (
                    <ChatDateEvent
                      key={m.createdAt}
                      createdDate={m.createdAt}
                    />
                  )
                ),
                <OutBoundMessage
                  key={m.messageId}
                  messageId={m.messageId}
                  fileURL={m.fileURL}
                  fileName={m.fileName}
                  messageType={m.messageType}
                  fileMime={m.fileMime}
                  message={m.message}
                  userId={m.userId}
                  customType={m.customType}
                  index={m.index}
                  isGroup={this.state.isGroup}
                  name={this.props.directoryMap.get(m.userId).name}
                  imageSrc={this.props.directoryMap.get(m.userId).imgsrc}
                  consecutive={consecutive}
                  createdAt={m.createdAt}
                  priority={m.priority}
                />
              ]
            : [
                index === 0 ? (
                  <ChatDateEvent key={m.createdAt} createdDate={m.createdAt} />
                ) : (
                  format(m.createdAt, "MM/dd/yyyy") !==
                    format(
                      this.state.listMessages[index - 1].createdAt,
                      "MM/dd/yyyy"
                    ) && (
                    <ChatDateEvent
                      key={m.createdAt}
                      createdDate={m.createdAt}
                    />
                  )
                ),
                <InBoundMessage
                  key={m.messageId}
                  messageId={m.messageId}
                  fileURL={m.fileURL}
                  fileName={m.fileName}
                  message={m.message}
                  userId={m.userId}
                  createdAt={m.createdAt}
                  displayedits={m.displayedits}
                  index={m.index}
                  customType={m.customType}
                  messageType={m.messageType}
                  fileMime={m.fileMime}
                  priority={m.priority}
                  unReadCount={m.unReadCount}
                  isGroup={this.state.isGroup}
                  totalCount={this.state.peerUserIds.length}
                  click={() => {
                    this.displayEdit(index);
                  }}
                  MouseLeave={() => {
                    this.hideEdit(index);
                  }}
                  deleteConfirmed={() => this.deleteMessage(index)}
                  updateConfirmed={() => this.updateMessage(index)}
                  messageDetails={() => {
                    var messageDetails = {
                      messageDetailsId: m.messageId,
                      messageDetailsType: m.messageType
                    };

                    this.setState({ messageDetails: messageDetails });
                  }}
                  forwardMessage={() => {
                    var forwardMessage = {
                      forwardMessageId: m.messageId,
                      forwardMessageType: m.messageType,
                      forwardMessageValue: m.message,
                      file: {
                        url: m.fileURL,
                        name: m.fileName,
                        type: m.fileMime
                      }
                    };

                    this.setState({ forwardMessage: forwardMessage });
                  }}
                  onChange={this.handleChange}
                  value={this.state.value}
                  placeholder={m.message}
                  initializeValue={() => {
                    this.setState({ value: m.message, updateMessageID: index });
                    console.log(this);
                    this.messageInput.current.focus();
                  }}
                  updateAsPriority={() => this.updateMessageAsPriority(index)}
                  emptyValue={() => this.setState({ value: "" })}
                />
              ];
        })}
        {/*    {this.state.typingUsersMap.forEach(m => {
          return (
            <li
              key="999999999"
              class="slds-chat-listitem slds-chat-listitem_inbound"
            >
              <div
                style={{ paddingBottom: "0px" }}
                className="slds-chat-message"
              >
                <span class="slds-avatar slds-avatar_circle slds-chat-avatar">
                  <Avatar label={m.name} imgSrc={m.imgsrc} inverse={true} />
                </span>

                <div class="slds-chat-message__body">
                  <div class="slds-chat-message__text slds-chat-message__text_inbound">
                    <span class="slds-icon-typing slds-is-animated">
                      <span class="slds-icon-typing__dot"></span>
                      <span class="slds-icon-typing__dot"></span>
                      <span class="slds-icon-typing__dot"></span>
                    </span>
                  </div>
                </div>
              </div>
            </li>
          );
        })} */}
      </ul>
    );
  }
}
export function OutBoundMessage(props) {
  return props.customType === "Event" ? (
    <ChatEvent
      messageId={props.messageId}
      message={props.name + " " + props.message}
    />
  ) : (
    <li className="slds-chat-listitem slds-chat-listitem_inbound">
      <div style={{ paddingBottom: "0px" }} className="slds-chat-message">
        <span class="slds-avatar slds-avatar_circle slds-chat-avatar">
          <Avatar label={props.name} imgSrc={props.imageSrc} inverse={true} />
        </span>
        <div className="slds-chat-message__body">
          <div
            className={
              props.priority
                ? "slds-chat-message__text slds-chat-message__text_delivery-failure"
                : "slds-chat-message__text slds-chat-message__text_inbound"
            }
          >
            {props.isGroup && (
              <div
                style={{
                  color: "#3e3e3c",
                  fontSize: "0.625rem"
                }}
              >
                {props.name}
              </div>
            )}
            {props.message && (
              <div dangerouslySetInnerHTML={getMarkdownText(props.message)} />
            )}

            {props.messageType === "file" && (
              <FileInput
                fileName={props.fileName}
                fileURL={props.fileURL}
                fileMime={props.fileMime}
              />
            )}
            {props.priority ? (
              <div
                class="slds-chat-message__text_delivery-failure-reason"
                role="alert"
              >
                <Icon size="x-small" path="symbols.svg#priorityOn" />
                <span style={{ marginLeft: "10px" }}>Priority Message</span>
              </div>
            ) : null}
          </div>

          <div class="slds-chat-message__meta">
            {format(props.createdAt, "hh:mm a")}
            {props.messageType === "file" && (
              <a href={props.fileURL} style={{ color: "rgb(10,132,255)" }}>
                <DownloadIcon></DownloadIcon>
              </a>
            )}
          </div>
        </div>
      </div>
    </li>
  );
}

export function ChatDateEvent(props) {
  return (
    <li
      key={props.createdDate}
      className="slds-chat-listitem slds-chat-listitem_event"
    >
      <div className="slds-chat-event">
        <div className="slds-chat-event__body">
          <i>{format(props.createdDate, "MM/dd/yyyy")}</i>
        </div>
      </div>
    </li>
  );
}

export function CircularIndeterminate() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}
    >
      <CircularProgress />
    </div>
  );
}

export function GroupChatIcon(props) {
  return (
    <svg
      style={props.style}
      width="20px"
      height="20px"
      role="img"
      viewBox="0 0 640 512"
    >
      <g>
        <path
          fill="currentColor"
          d="M96 224a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64zm480 32h-64a63.81 63.81 0 0 0-45.1 18.6A146.27 146.27 0 0 1 542 384h66a32 32 0 0 0 32-32v-32a64.06 64.06 0 0 0-64-64zm-512 0a64.06 64.06 0 0 0-64 64v32a32 32 0 0 0 32 32h65.9a146.64 146.64 0 0 1 75.2-109.4A63.81 63.81 0 0 0 128 256zm480-32a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64z"
          opacity="0.4"
        ></path>
        <path
          fill="currentColor"
          d="M396.8 288h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 128 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 396.8 288zM320 256a112 112 0 1 0-112-112 111.94 111.94 0 0 0 112 112z"
        ></path>
      </g>
    </svg>
  );
}
export function DownloadIcon(props) {
  return (
    <svg
      style={props.style}
      width="20px"
      height="10px"
      role="img"
      viewBox="0 0 448 512"
    >
      <path
        fill="currentColor"
        d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-143.6-28.9L288 302.6V120c0-13.3-10.7-24-24-24h-16c-13.3 0-24 10.7-24 24v182.6l-72.4-75.5c-9.3-9.7-24.8-9.9-34.3-.4l-10.9 11c-9.4 9.4-9.4 24.6 0 33.9L239 404.3c9.4 9.4 24.6 9.4 33.9 0l132.7-132.7c9.4-9.4 9.4-24.6 0-33.9l-10.9-11c-9.5-9.5-25-9.3-34.3.4z"
      ></path>
    </svg>
  );
}

const emojis = require("emojis-list");
