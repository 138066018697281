import React from "react";
import _ from "lodash";
import Modal from "@salesforce/design-system-react/components/modal";
import Divider from "@material-ui/core/Divider";
import Button from "@salesforce/design-system-react/components/button/";
import Avatar from "@salesforce/design-system-react/components/avatar";
import PillContainer from "@salesforce/design-system-react/components/pill-container";
import * as items from "./Items.jsx";

class AddMembers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      members: this.props.members,
      options: [],
      filteredMap: this.props.directoryMap,
      value: ""
    };
    this.optionsfromSet = this.optionsfromSet.bind(this);
    this.filter = this.filter.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  filter(event) {
    let searchValue = event.target.value;
    console.log(searchValue);
    let newMap = new Map(this.props.directoryMap);
    function logMapElements(value, key, map) {
      if (!value.name.toLowerCase().includes(searchValue.toLowerCase()))
        newMap.delete(key);
    }
    // If the search bar isn't empty
    if (searchValue !== "") {
      // Assign the original list to currentList
      this.props.directoryMap.forEach(logMapElements);
    }
    console.log(newMap);
    this.setState({
      filteredMap: newMap,
      value: searchValue
    });
    // Set the filtered state based on what our rules added to newList
  }

  onRequestRemovePill = (event, data) => {
    let mems = this.state.members;
    console.log(mems.delete(data.option.id));
    this.setState({
      members: mems,
      options: this.optionsfromSet(mems)
    });
  };
  handleChange(event) {
    if (event.target.files !== undefined) {
      this.setState({ file: event.target.files[0] });
    }
  }

  optionsfromSet(members) {
    let options = [];

    members.forEach(member => {
      let item = this.props.directoryMap.get(member);
      options.push({
        avatar: (
          <Avatar imgSrc={item.imgsrc} label={item.name} inverse={true} />
        ),
        id: item.userId,
        label: item.name,
        title: item.name
      });
    });
    return options;
  }

  render() {
    return (
      <Modal
        isOpen={this.props.addMembers}
        heading="Add Members"
        footer={[
          <Button label="Cancel" onClick={this.props.close} />,
          this.state.groupName === "" || this.state.members.size < 1 ? (
            <Button label="Done" disabled variant="brand" />
          ) : (
            <Button
              label="Done"
              onClick={() => {
                this.props.addMembersToGroup(Array.from(this.state.members));
              }}
              variant="brand"
            />
          )
        ]}
        onRequestClose={this.props.close}
        size="small"
      >
        <section className="slds-p-around_large">
          <div className="slds-form-element slds-m-bottom_large">
            <label className="slds-form-element__label">Add Members</label>
            <div className="slds-grid slds-grid_vertical-align-start">
              <PillContainer
                id="pill-container-with-avatars"
                options={this.state.options}
                onRequestRemovePill={this.onRequestRemovePill}
              />
            </div>

            <div
              style={{
                height: "40px",
                backgroundColor: "white",
                border: "solid transparent",
                borderRadius: "20px"
              }}
              className="slds-grid slds-grid_align-spread"
            >
              <input
                className="slds-col"
                value={this.state.value}
                style={{
                  backgroundColor: "transparent",
                  paddingRight: "0px",
                  border: "none",
                  outline: 0
                }}
                placeholder="search"
                onChange={this.filter}
              ></input>
            </div>

            <Divider />
            <ul
              style={{
                height: "180px",
                overflow: "scroll",
                overflowScrolling: "touch",
                WebkitOverflowScrolling: "touch"
              }}
            >
              {_.orderBy(
                Array.from(this.state.filteredMap.values()),
                ["name"],
                ["asc"]
              ).map((item, index) => (
                <items.DirectoryItem
                  key={index}
                  item={item}
                  onClick={() => {
                    let mems = this.state.members;
                    console.log(mems.add(item.userId));
                    this.setState({
                      members: mems,
                      options: this.optionsfromSet(mems)
                    });
                  }}
                />
              ))}
            </ul>
          </div>
        </section>
      </Modal>
    );
  }
}
export default AddMembers;
