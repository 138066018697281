import React, { Component } from "react";
export default class ChatEvent extends Component {
  render() {
    return (
      <li
        key={this.props.messageId}
        className="slds-chat-listitem slds-chat-listitem_event"
      >
        <div className="slds-chat-event">
          <div className="slds-chat-event__body">
            <i>{this.props.message}</i>
          </div>
        </div>
      </li>
    );
  }
}
